import React from 'react'
import { useSelector } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { DEFAULT_LANGUAGE_KEY } from '../../Constants/MainKeys'
import BecomeSupporter from '../../Pages/AuthUserPages/BecomeSupporter/BecomeSupporter'
import Home from '../../Pages/GeneralPages/Home/Home'
import ChangePassword from '../../Pages/UnAuthUserPages/ChangePassword/ChangePassword'
import ForgotPassword from '../../Pages/UnAuthUserPages/ForgotPassword/ForgotPassword'
import SignIn from '../../Pages/UnAuthUserPages/SignIn/SignIn'
import SignUp from '../../Pages/UnAuthUserPages/SignUp/SignUp'
import BecomeSupporterViaUrl from "../../Pages/UnAuthUserPages/BecomeSupporterViaUrl/BecomeSupporterViaUrl"
import RestorePassword from '../../Pages/UnAuthUserPages/RestorePassword/RestorePassword'
import AboutUs from '../../Pages/UnAuthUserPages/AboutUs/AboutUs'
import CookiesPolicy from '../../Pages/UnAuthUserPages/Resources/CookiesPolicy'
import PrivacyPolicy from '../../Pages/UnAuthUserPages/Resources/PrivacyPolicy'
import ContactUsUnAuth from '../../Pages/UnAuthUserPages/ContactUsUnAuth/ContactUsUnAuth'
import Subscriptions from '../../Pages/UnAuthUserPages/Subscriptions/Subscriptions'
import Help from '../../Pages/GeneralPages/Help/Help'
import TermsAndConditions from '../../Pages/UnAuthUserPages/Resources/TermsAndConditions'
import Pricing from '../../Pages/GeneralPages/Pricing/Pricing'


export default function UnauthUsersRouting() {

  const { language } = useSelector(state => state.language);

  return <Switch>
    <Route path="/:language" exact component={Home} />
    <Route path="/:language/sign-up" exact component={SignUp} />
    <Route path="/:language/sign-in" exact component={SignIn} />
    <Route path="/:language/about-us" exact component={AboutUs} />
    <Route path="/:language/forgot-password" exact component={ForgotPassword} />
    <Route path="/:language/restore-password" exact component={RestorePassword} />
    <Route path="/:language/user/resetpasswordaccess" exact component={ChangePassword} />
    <Route path="/:language/partner/invitationaccess" exact component={BecomeSupporter} />
    <Route path="/:language/technical-assistant/registrationaccess" exact component={BecomeSupporterViaUrl} />
    <Route path="/:language/contact-us" exact component={ContactUsUnAuth} />
    <Route path="/:language/privacy-policy" exact component={PrivacyPolicy} />
    <Route path="/:language/terms-and-conditions" exact component={TermsAndConditions} />
    <Route path="/:language/cookies-policy" exact component={CookiesPolicy} />
    <Route path="/:language/subscriptions/:paymentPackageId" exact component={Subscriptions} />
    <Route path="/:language/help" exact component={Help} />
    <Route path="/:language/pricing" exact component={Pricing} />

    <Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}`} />
  </Switch>
}
