import React, { useState, useEffect, useCallback } from 'react';
import { InputGroup } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AlertService from '../../../../Services/AlertService';
import { ERROR_KEY, PARTNER_USER_TYPE_ID_KEY, SUPERVISOR_USER_TYPE_ID_KEY, fieldLengths } from '../../../../Constants/MainKeys';
import ApiService from '../../../../Services/ApiService';
import NoData from '../../../../Components/NoData/NoData';
import Auxiliary from '../../../../hoc/auxiliary/auxiliary';
import TranslationService from '../../../../Services/translationService';
import CustomInput from "../../../../Components/Inputs/Input"
import uuid from 'react-uuid';
import {
    setActiveUserData,
    setDefaultTechnicalAssistants,
    setTechnicalAssistants,
} from "../../../../Store/Actions/chat";
import {
    addSpinner,
    removeSpinner,
} from '../../../../Store/Actions/spinner';
import { AiOutlineUser } from "react-icons/ai";
import ReactPaginate from 'react-paginate';
import moment from 'moment';


const TicketsPage = (props) => {

    const dispatch = useDispatch();
    const { activeUserData } = useSelector(state => state.chat);
    const { language } = useSelector(state => state.language);
    const { translations } = useSelector(state => state.translation);
    const { user } = useSelector(state => state.user);

    const [searchText, setSearchText] = useState("");
    const [tickets, setTickets] = useState([
        { title: "Project Alpha", description: "First project in the series, focusing on the basics.", status: "New", createDate: "2025-01-15T08:30:00Z" },
        { title: "Project Beta", description: "Second project with advanced features and optimization.", status: "New", createDate: "2025-01-16T09:00:00Z" },
        { title: "Project Gamma", description: "Exploring new concepts in programming and architecture.", status: "New", createDate: "2025-01-17T10:30:00Z" },
        { title: "Project Delta", description: "A deep dive into backend development and database management.", status: "New", createDate: "2025-01-18T11:00:00Z" },
        { title: "Project Epsilon", description: "Exploring cloud computing and scalability challenges.", status: "New", createDate: "2025-01-19T12:00:00Z" },
        { title: "Project Zeta", description: "Focusing on AI integration and machine learning algorithms.", status: "New", createDate: "2025-01-20T13:00:00Z" },
        { title: "Project Eta", description: "Working with IoT devices and real-time data processing.", status: "New", createDate: "2025-01-21T14:00:00Z" },
        { title: "Project Theta", description: "A new approach to front-end development using modern frameworks.", status: "New", createDate: "2025-01-22T15:00:00Z" },
        { title: "Project Iota", description: "Researching blockchain technologies and decentralized systems.", status: "New", createDate: "2025-01-23T16:00:00Z" },
        { title: "Project Kappa", description: "An innovative project involving virtual and augmented reality.", status: "New", createDate: "2025-01-24T17:00:00Z" }
    ]);
    const [activePageNumber, setActivePageNumber] = useState(0);
    const [pagination, setPagination] = useState(null);
    const [translationService, setTranslationService] = useState(null);

    const handlePageClick = (event) => {
        setActivePageNumber(event.selected);
        // getTechnicalAssistants(event.selected + 1, key)
    }

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const setSpinner = useCallback(spinner => {
        dispatch(addSpinner(spinner));
    }, []);

    const extractSpinner = useCallback(spinner => {
        dispatch(removeSpinner(spinner));
    }, []);

    const getFail = (error, spinnerId) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
        spinnerId && extractSpinner(spinnerId);
    };

    return translationService ? <Auxiliary>
        {
            <div className='vnd-list-container'>
                <aside>
                    <div className="p-3 left-bar-header align-items-center">
                        <h4 className="vnd--upp-text-20">{translationService.translate("TR_TICKETS")}</h4>
                    </div>
                    <hr className='m-0 mb-2' />
                </aside>
                <div className="px-2 mb-auto">

                    {
                        tickets && tickets.length ?
                            <Auxiliary>
                                <div className="mt-4">
                                    <div className='table-responsive vnd--borderd vnd--m-block'>
                                        <table className="table mb-0">
                                            <thead className="table-dark">
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">{translationService.translate("TR_TITLE")}</th>
                                                    <th scope="col">{translationService.translate("TR_STATUS")}</th>
                                                    <th scope="col">{translationService.translate("TR_DATE")}</th>
                                                    <th scope="col">{translationService.translate("TR_DESCRIPTION")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    tickets.map((item, index) => {
                                                        return <tr key={index}>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{item.title}</td>
                                                            <td>{item.status}</td>
                                                            <td>{moment(item.createDate).format("L")}</td>
                                                            <td>{item.description}</td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {
                                    pagination && pagination.totalPages > 1 ?
                                        <div className='w-100 d-flex justify-content-end mt-4'>
                                            <ReactPaginate
                                                nextLabel={translationService.translate("TR_NEXT")}
                                                onPageChange={handlePageClick}
                                                pageRangeDisplayed={3}
                                                marginPagesDisplayed={2}
                                                pageCount={pagination.totalPages}
                                                previousLabel={translationService.translate("TR_PREVIOUS")}
                                                pageClassName="page-item"
                                                pageLinkClassName="page-link"
                                                previousClassName="page-item"
                                                previousLinkClassName="page-link"
                                                nextClassName="page-item"
                                                nextLinkClassName="page-link"
                                                breakLabel="..."
                                                breakClassName="page-item"
                                                breakLinkClassName="page-link"
                                                containerClassName="pagination"
                                                activeClassName="active"
                                                renderOnZeroPageCount={null}
                                                forcePage={activePageNumber}
                                            />
                                        </div>
                                        : null
                                }
                            </Auxiliary>
                            : <NoData />
                    }
                </div>
            </div>
        }
    </Auxiliary> : null
}

export default withRouter(TicketsPage)
