import React, { useCallback, useEffect, useState } from 'react';
import Input from '../../../../Components/Inputs/Input';
import MainService from '../../../../Services/mainService';
import { useDispatch, useSelector } from 'react-redux';
import { addButtonSpinner, removeButtonSpinner } from '../../../../Store/Actions/spinner';
import AlertService from '../../../../Services/AlertService';
import { ERROR_KEY, SUCCESS_KEY, fieldLengths } from '../../../../Constants/MainKeys';
import uuid from 'react-uuid';
import ActionButton from '../../../../Components/Buttons/ActionButton';
import ApiService from '../../../../Services/ApiService';
import TranslationService from '../../../../Services/translationService';


const buttonSpinnerId = uuid();
export default function ProfileDataBlock(props) {

  const { isShowCancelButton, setIsShowTabs } = props;
  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const [translationService, setTranslationService] = useState(null);
  const [password, setpassword] = useState("");
  const [newPassword, setnewPassword] = useState("");
  const [isInvalidNewPassword, setIsInvalidNewPassword] = useState(false);
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [isFormChanged, setIsFormChanged] = useState(false);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const checkFieldsValidation = (field, fieldName) => {
    var isValid = MainService.isValidField(field, fieldName);

    if (fieldName === "newPassword") {
      changeIsinvalidFieldName(isValid, setIsInvalidNewPassword);
    }
    switch (fieldName) {
      case "newPassword":
        changeIsinvalidFieldName(isValid, setIsInvalidNewPassword)
        break;
      default:
        break;
    }
  }

  const changeIsinvalidFieldName = (isValid, cb) => {
    if (!isValid) cb(true);
    else cb(false);
  }

  const onSubmit = (event) => {
    event.preventDefault();
    if (!password.trim().length || !newPassword.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      const form = {
        oldPassword: password,
        password: btoa(newPassword),
      }
      setButtonSpinner(buttonSpinnerId);
      ApiService.changeOldPassword(form).then(() => {
        extractButtonSpinner(buttonSpinnerId);
        setpassword("");
        setnewPassword("");
        cancel();
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_PASSWORD_CHANGED"));
      }).catch(error => getFail(error, buttonSpinnerId))
    }
  }

  const cancel = () => {
    setpassword("");
    setnewPassword("");
    setIsInvalidNewPassword(false);
    setIsInvalidSubmit(false);
    setIsFormChanged(false);
    setIsShowTabs(false);
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return translationService ? <div className="p-3">
    <form onSubmit={onSubmit} onChange={() => setIsFormChanged(true)}>
      <div className='row'>
        <div className="col-lg-6 col-12">
          <Input
            id="password"
            htmlFor="password"
            type="password"
            name="password"
            labelValue={`${translationService.translate("TR_CURRENT_PASSWORD")}*`}
            value={password}
            autoComplete="new-password"
            inputClassName={`${(isInvalidSubmit && !password.trim()) ? "error-border" : ""}`}
            onChange={(event) => {
              onChange(event, setpassword, fieldLengths.maxLength_100);
              setIsInvalidSubmit(false);
            }}
            onBlur={() => checkFieldsValidation(password, "password")}
          />
        </div>
        <div className="col-lg-6 col-12">
          <Input
            id="newPassword"
            htmlFor="newPassword"
            type="password"
            name="newPassword"
            labelValue={`${translationService.translate("TR_NEW")} ${translationService.translate("TR_PASSWORD")} *`}
            value={newPassword}
            autoComplete="new-password"
            inputClassName={`${isInvalidSubmit && (!newPassword.trim() || isInvalidNewPassword) ? "error-border" : ""}`}
            onChange={(event) => {
              onChange(event, setnewPassword, fieldLengths.maxLength_100);
              setIsInvalidSubmit(false);
            }}
            onBlur={() => checkFieldsValidation(newPassword, "newPassword")}
          />
        </div>
      </div>
      {
        isInvalidNewPassword ?
          <small className="red-color">{translationService.translate("TR_PASSWORD_LENGTH_VAL").replace('{0}', "6").replace('{1}', "100")}</small>
          : null
      }
      <div className='d-flex justify-content-end'>
        <ActionButton
          type="submit"
          name={translationService.translate("TR_SAVE_CHANGES")}
          className={`vnd-btn vnd-primary-btn my-3 ${!isFormChanged || isInvalidNewPassword || !newPassword.trim().length || !password.trim().length ? "disabled" : ""}`}
          disabled={!isFormChanged || isInvalidNewPassword || !newPassword.trim().length || !password.trim().length}
          spinnerId={buttonSpinnerId}
          onClick={onSubmit}
        />
        {
          isShowCancelButton ?
            <button className='vnd-btn vnd-primary-outline-btn my-3 ms-2' type='button' onClick={cancel}>
              {translationService.translate("TR_CANCEL")}
            </button>
            : null
        }
      </div>
    </form>
    <hr />
    <div className='vnd-text-block danger-background'>
      <p>{translationService.translate("TR_CHANGE_PASSWORD_INFO")}</p>
    </div>
  </div> : null
}
