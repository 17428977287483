import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, withRouter } from 'react-router-dom';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import { setActiveGroupName, setActiveSection, setPartnerId } from '../../../Store/Actions/main';
import { ACTIVE_SECTION_KEY, LANGUAGE_KEY, PARTNER_USER_TYPE_ID_KEY, PROJECT_NAME, SPINNER_COLOR } from '../../../Constants/MainKeys';
import { logout } from '../../../Store/Actions/user';
import { setconversationNameInOpenChat } from '../../../Store/Actions/layout';
import { setActiveUserData } from '../../../Store/Actions/chat';
import { setLanguage } from '../../../Store/Actions/language';
import { getTranslations } from '../../../Store/Actions/translation';
import TranslationService from '../../../Services/translationService';
import PuffLoader from "react-spinners/PuffLoader";
import logoSvg from "../../../assets/logo/logo-big.png";
import { AiOutlineUser } from "react-icons/ai";


const useOutsideAlerter = (ref, setIsShowNavbar, setIsShowFlags, setIsShowUserPopup) => {

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsShowFlags(false);
        setIsShowUserPopup(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Header = (props) => {
  const { pathname } = useLocation();

  const wrapperRef = useRef(null);
  const dispatch = useDispatch();
  const user = useSelector(state => state.user.user);
  const language = useSelector(state => state.language.language);
  const languages = useSelector(state => state.language.languages);
  const translations = useSelector(state => state.translation.translations);
  const { isShowFlagSpinner } = useSelector(state => state.spinner);

  const [translationService, setTranslationService] = useState(null);
  const activeSection = useSelector(state => state.main.activeSection);
  const [isShowNavbar, setIsShowNavbar] = useState(false);
  const [isShowFlags, setIsShowFlags] = useState(false);
  const [isShowUserPopup, setIsShowUserPopup] = useState(false);

  useOutsideAlerter(
    wrapperRef,
    setIsShowNavbar,
    setIsShowFlags,
    setIsShowUserPopup
  );

  useEffect(() => {
    localStorage.removeItem(ACTIVE_SECTION_KEY);
    setActiveSectionName("home");
  }, [])

  const closeLanguageBlock = () => {
    var checkLanguage = document.getElementById("checkLanguage");
    var checkLanguageItem = document.getElementById("checkLanguageItem");
    checkLanguage && checkLanguage.click();
    checkLanguageItem && checkLanguageItem.click();
  }

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const setActiveSectionName = (name) => {
    dispatch(setActiveSection(name))
  }

  const logOut = () => {
    props.history.push(language);
    dispatch(logout());
    dispatch(setActiveGroupName(null));
    dispatch(setconversationNameInOpenChat("group"));
    dispatch(setPartnerId(null));
    dispatch(setActiveUserData(null));
  }

  const changeLanguage = isocode => {
    if (!isocode || isocode === language) {
      closeLanguageBlock();
      return false;
    }
    localStorage.setItem(LANGUAGE_KEY, isocode);
    setLanguageHandler(isocode);
    const newPath = pathname.toString().replace(props.match.path.toString(), `/${isocode}`);
    props.history.push(newPath.toString());
    closeLanguageBlock();
    dispatch(getTranslations(true));
  }

  const setLanguageHandler = useCallback(language => {
    dispatch(setLanguage(language));
  }, []);

  const scrollToCurrentPosition = (activeSection) => {
    const currentSection = document.getElementById(activeSection);
    currentSection && window.scrollTo({
      top: currentSection?.getBoundingClientRect().top + document.documentElement.scrollTop - 50,
      behavior: "smooth"
    });
  }

  return translationService ? <header className="vnd-header vnd-box-shadow">
    <div className="container">
      <nav className="vnd-navbar vnd-navbar-expand-lg vnd-light-navbar">
        <Link className="vnd-navbar-brand" to={`/${language}`} onClick={() => {
          setActiveSectionName("home")
          scrollToCurrentPosition("home")
        }}
        >
          <img className='w-100' src={logoSvg} alt={PROJECT_NAME} />
          {/* {PROJECT_NAME} */}
        </Link>
        <div className="burger-menu-wrapper d-lg-none d-flex align-items-center ml-auto">
          {/*responsive menu */}
          <div className="current-language">
            {
              isShowFlagSpinner ?
                <div className='d-flex'>
                  <PuffLoader size={30} color={SPINNER_COLOR} />
                </div>
                : <>
                  {
                    languages && languages.length ?
                      languages.map(languageItem => {
                        if (languageItem.isocode2 === language) {
                          return <div key={languageItem.id}><img src={languageItem.flagPath} alt="flag" /></div>
                        }
                      })
                      : null
                  }
                  <input id="checkLanguage" type="checkbox" checked={isShowFlags} onChange={() => setIsShowFlags(!isShowFlags)} />
                </>
            }
            <ul className="falges">
              {
                languages && languages.length ?
                  languages.map(languageItem => {
                    return <li key={languageItem.id}>
                      <div className="flag" rel={languageItem.isocode2}>
                        <img
                          src={languageItem.flagPath}
                          alt={languageItem.isocode2}
                          onClick={() => changeLanguage(languageItem.isocode2)}
                        />
                      </div>
                    </li>
                  })
                  : null
              }
            </ul>
          </div>
          {
            user ?
              <div className="signed-customer-modal-wrapper">
                <div className="vnd-signed-nav-link">
                  <input
                    type="checkbox"
                    checked={isShowNavbar}
                    onChange={() => setIsShowNavbar(!isShowNavbar)}
                  />
                  <span className='burger-icon'></span>
                  <span className='burger-icon'></span>
                  <span className='burger-icon'></span>
                  <div className="burger-menu">
                    <ul className="">
                      <li className="vnd-nav-burger-item">
                        <Link to={`/${language}/profile`} onClick={() => setIsShowNavbar(!isShowNavbar)} className="p-0">
                          {
                            user.avatarPath ?
                              <div className="avatar vnd-customer-avatar">
                                <div className="signed-avatar vnd-bkg-cover-img" style={{ backgroundImage: `url(${user.avatarPath})` }} />
                              </div>
                              : <div className="chat-avatar" style={{ width: "35px", height: "35px" }}>
                                <div className="chat-user-img align-self-center">

                                  <div className="avatar-xs mb-2">
                                    <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                      {user.fullName && user.fullName.trim().length ? user.fullName.charAt(0) : user.firstname ? user.firstname.charAt(0) : <AiOutlineUser />}
                                    </span>
                                  </div>
                                </div>
                              </div>
                          }
                          <h3 className="signed-cutomer-name">{user.fullName}</h3>
                        </Link>
                      </li>
                      <li className="hr"></li>
                      <li className={`vnd-nav-burger-link ${activeSection && (activeSection.includes("home") || activeSection.includes("relamy")) ? "active" : ""}`}>
                        <Link
                          className="vnd-nav-burger-link"
                          to={`/${language}`}
                          onClick={() => {
                            setActiveSectionName("home")
                            setIsShowNavbar(false)
                          }}
                        >
                          {PROJECT_NAME}
                        </Link>
                      </li>
                      <li className={`vnd-nav-burger-link ${activeSection && activeSection.includes("partners") ? "active" : ""}`}>
                        <Link
                          className="vnd-nav-burger-link"
                          to={`/${language}`}
                          onClick={() => {
                            setActiveSectionName("partners")
                            setIsShowNavbar(false)
                          }}
                        >
                          {translationService.translate("TR_PARTNERS")}
                        </Link>
                      </li>
                      <li className={`vnd-nav-burger-link ${activeSection && activeSection.includes("service") ? "active" : ""}`}>
                        <Link
                          className="vnd-nav-burger-link"
                          to={`/${language}`}
                          onClick={() => {
                            setActiveSectionName("service")
                            setIsShowNavbar(false)
                          }}
                        >{translationService.translate("TR_SERVICE")}</Link>
                      </li>
                      <li className={`vnd-nav-burger-link ${activeSection && activeSection.includes("contact-us") ? "active" : ""}`}>
                        <Link
                          className="vnd-nav-burger-link"
                          to={`/${language}/contact-us`}
                        // onClick={() => {
                        //   setActiveSectionName("contact-us")
                        //   setIsShowNavbar(false)
                        // }}
                        >{translationService.translate("TR_CONTACT_US")}</Link>
                      </li>
                      <li className="vnd-nav-burger-item">
                        <Link className="vnd-nav-burger-link" to="#" onClick={logOut}>{translationService.translate("TR_SIGNOUT")}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              : <div className="unsigned-customer position-relative">
                <input
                  type="checkbox"
                  checked={isShowNavbar}
                  onChange={() => setIsShowNavbar(!isShowNavbar)}
                />
                <span className='burger-icon'></span>
                <span className='burger-icon'></span>
                <span className='burger-icon'></span>
                <div className="burger-menu">
                  <ul className="">
                    <li className={`vnd-nav-burger-link ${activeSection && (activeSection.includes("home") || activeSection.includes("relamy")) ? "active" : ""}`}>
                      <Link
                        className="vnd-nav-burger-link"
                        to={`/${language}`}
                        onClick={() => {
                          setActiveSectionName("home")
                          setIsShowNavbar(false)
                        }}
                      >{PROJECT_NAME}</Link>
                    </li>
                    <li className={`vnd-nav-burger-link ${activeSection && activeSection.includes("service") ? "active" : ""}`}>
                      <Link
                        className="vnd-nav-burger-link"
                        to={`/${language}`}
                        onClick={() => {
                          setActiveSectionName("service")
                          setIsShowNavbar(false);
                        }}
                      >
                        {translationService.translate("TR_SERVICE")}
                      </Link>
                    </li>
                    {/* <li className={`vnd-nav-burger-link ${activeSection && activeSection.includes("resources") ? "active" : ""}`}>
                      <Link
                        className="vnd-nav-burger-link"
                        to={`/${language}`}
                        onClick={() => {
                          setActiveSectionName("resources")
                          setIsShowNavbar(false);
                        }}
                      >
                        {translationService.translate("TR_RESOURCES")}
                      </Link>
                    </li> */}
                    <li className={`vnd-nav-burger-link ${activeSection && activeSection.includes("pricing") ? "active" : ""}`}>
                      <Link
                        className="vnd-nav-burger-link"
                        to={`/${language}/pricing`}
                        onClick={() => {
                          setActiveSectionName("pricing")
                          setIsShowNavbar(false);
                        }}
                      >
                        {translationService.translate("TR_PRICING")}
                      </Link>
                    </li>
                    <li className={`vnd-nav-burger-link ${pathname.includes("contact-us") ? "active" : ""}`}>
                      <Link
                        className="vnd-nav-burger-link"
                        to={`/${language}/contact-us`}
                        onClick={() => {
                          setActiveSectionName("contact-us")
                          setIsShowNavbar(false);
                        }}
                      >
                        {translationService.translate("TR_CONTACT_US")}
                      </Link>
                    </li>
                    <li className={`vnd-nav-burger-link ${activeSection && activeSection.includes("about-us") ? "active" : ""}`}>
                      <Link
                        className="vnd-nav-burger-link"
                        to={`/${language}/about-us`}
                        onClick={() => {
                          setActiveSectionName("about-us")
                          setIsShowNavbar(false);
                        }}
                      >
                        {translationService.translate("TR_ABOUT")}
                      </Link>
                    </li>
                    <li className={`vnd-nav-burger-link ${activeSection && activeSection.includes("help") ? "active" : ""}`}>
                      <Link
                        className="vnd-nav-burger-link"
                        to={`/${language}/help`}
                        onClick={() => {
                          setActiveSectionName("help");
                          setIsShowNavbar(false);
                        }}
                      >
                        {translationService.translate("TR_HELP")}
                      </Link>
                    </li>
                    <li className={`vnd-nav-burger-link ${activeSection && activeSection.includes("sign-in") ? "active" : ""}`}>
                      <Link
                        className="vnd-nav-burger-link"
                        to={`/${language}/sign-in`}
                        onClick={() => {
                          setActiveSectionName("sign-in")
                          setIsShowNavbar(false);
                        }}
                      >
                        {translationService.translate("TR_SIGNIN")}
                      </Link>
                    </li>

                  </ul>
                </div>
              </div>
          }
        </div>
        <ul className="vnd-navbar-nav ms-auto d-lg-flex d-none">
          <Auxiliary>
            <li className={`vnd-nav-item ${activeSection && activeSection.includes("service") ? "active" : ""}`} >
              <Link className="vnd-nav-link" to={`/${language}`} onClick={() => setActiveSectionName("service")}>{translationService.translate("TR_SERVICE")}</Link>
            </li>
            {/* <li className={`vnd-nav-item ${activeSection && activeSection.includes("resources") ? "active" : ""}`} >
              <Link className="vnd-nav-link" to={`/${language}`} onClick={() => setActiveSectionName("resources")}>{translationService.translate("TR_RESOURCES")}</Link>
            </li> */}
            <li className={`vnd-nav-item ${activeSection && activeSection.includes("pricing") ? "active" : ""}`} >
              {/* <Link className="vnd-nav-link" to={`/${language}`} onClick={() => setActiveSectionName("pricing")}>{translationService.translate("TR_PRICING")}</Link> */}
              <Link className="vnd-nav-link" to={`/${language}/pricing`} onClick={() => setActiveSectionName("pricing")}>{translationService.translate("TR_PRICING")}</Link>
            </li>
            <li className={`vnd-nav-item ${pathname.includes("contact-us") ? "active" : ""}`}>
              <Link className="vnd-nav-link" to={`/${language}/contact-us`} onClick={() => setActiveSectionName("content-us")}>{translationService.translate("TR_CONTACT_US")}</Link>
            </li>
            <li className={`vnd-nav-item ${pathname.includes("about-us") ? "active" : ""}`} >
              <Link className="vnd-nav-link" to={`/${language}/about-us`} onClick={() => setActiveSectionName("about-us")}>{translationService.translate("TR_ABOUT")}</Link>
            </li>
            <li className={`vnd-nav-item ${pathname.includes("help") ? "active" : ""}`} >
              <Link className="vnd-nav-link" to={`/${language}/help`} onClick={() => setActiveSectionName("help")}>{translationService.translate("TR_HELP")}</Link>
            </li>
          </Auxiliary>
        </ul>
        <ul className="vnd-navbar-nav ms-auto d-lg-flex d-none">
          <Auxiliary>
            <li className="vnd-nav-item d-flex align-items-center">
              <div className="current-language">
                {
                  isShowFlagSpinner ?
                    <div className='d-flex'>
                      <PuffLoader size={30} color={SPINNER_COLOR} />
                    </div>
                    : <>
                      {
                        languages && languages.length ?
                          languages.map(languageItem => {
                            if (languageItem.isocode2 === language) {
                              return <div key={languageItem.id}><img src={languageItem.flagPath} alt="flag" /></div>
                            }
                          })
                          : null
                      }
                      <input id="checkLanguageItem" type="checkbox" checked={isShowFlags} onChange={() => setIsShowFlags(!isShowFlags)} />
                    </>
                }
                <ul className="falges">
                  {
                    languages && languages.length ?
                      languages.map(languageItem => {
                        return <li key={languageItem.id}>
                          <div className="flag" rel={languageItem.isocode2}>
                            <img
                              src={languageItem.flagPath}
                              alt={languageItem.isocode2}
                              onClick={() => changeLanguage(languageItem.isocode2)}
                            />
                          </div>
                        </li>
                      })
                      : null
                  }
                </ul>
              </div>
            </li>
            {
              user ?
                <li className="vnd-nav-item signed-customer-modal-wrapper">
                  <div className="vnd-signed-nav-link" ref={wrapperRef}>
                    <input
                      type="checkbox"
                      checked={isShowUserPopup}
                      onChange={() => setIsShowUserPopup(!isShowUserPopup)}
                    />
                    {
                      user.avatarPath ?
                        <div className="avatar vnd-customer-avatar">
                          <div className="signed-avatar vnd-bkg-cover-img" style={{ backgroundImage: `url(${user.avatarPath})` }} />
                        </div>
                        : <div className="chat-avatar" style={{ width: "35px", height: "35px" }}>
                          <div className="chat-user-img align-self-center">
                            <div className="avatar-xs mb-2">
                              <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                {user.fullName && user.fullName.trim().length ? user.fullName.charAt(0) : user.firstname ? user.firstname.charAt(0) : <AiOutlineUser />}
                              </span>
                            </div>
                          </div>
                        </div>
                    }

                    <ul className="signed-user-modal">
                      <li>
                        <h3 className="signed-cutomer-name">{user.email}</h3>
                      </li>
                      {
                        user && user.userTypeId === PARTNER_USER_TYPE_ID_KEY ?
                          <li><Link to={`/${language}/dashboard/chats/inbox`} onClick={() => setIsShowUserPopup(!isShowUserPopup)}>{translationService.translate("TR_CHATBOARD")}</Link></li>
                          : null
                      }
                      <li><Link to={`/${language}/profile`} onClick={() => setIsShowUserPopup(!isShowUserPopup)}>{translationService.translate("TR_PROFILE")}</Link></li>
                      <li><Link to="#">{PROJECT_NAME} API</Link></li>
                      <li className="hr"></li>
                      <li><Link to="#" onClick={logOut}>{translationService.translate("TR_SIGNOUT")}</Link></li>
                    </ul>
                  </div>
                </li>
                :
                <li className={`vnd-nav-item ml-4 ${activeSection && activeSection.includes("sign-in") ? "active" : ""}`}>
                  <Link className="link-button vnd-btn vnd-primary-btn vnd-small-btn" to={`/${language}/sign-in`} onClick={() => setActiveSectionName("sign-in")}>{translationService.translate("TR_SIGNIN")}</Link>
                </li>
            }
          </Auxiliary>
        </ul>

      </nav>
    </div>
  </header> : null
}

export default withRouter(Header)