import React, { useCallback, useEffect, useState } from 'react';
import { Row, Button } from "reactstrap";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { openUserSidebar, closeUserSidebar } from "./../../../../Store/Actions/layout";
import TranslationService from '../../../../Services/translationService';
import { ERROR_KEY, SUCCESS_KEY, SUPERVISOR_USER_TYPE_ID_KEY, SUPPORTER_USER_TYPE_ID_KEY, fieldLengths } from '../../../../Constants/MainKeys';
import AlertService from '../../../../Services/AlertService';
import ApiService from './../../../../Services/ApiService';
import ActionButton from '../../../../Components/Buttons/ActionButton';
import uuid from 'react-uuid';
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../../Store/Actions/spinner';
import * as moment from "moment";
import MainService from '../../../../Services/mainService';
import { setTicketStatusResponse, setTicketUrgentResponse } from '../../../../Store/Actions/main';
import useWindowSize from '../../../../Hooks/useWindowSize';
import {
    addFieldsToActiveUserDataFromSession,
    addMessagesToUser,
    getCountries,
    getScriptGroups,
    setActiveUserData,
    setCountries,
    setDefaultTechnicalAssistants,
    setScriptGroups,
    setScripts,
    setSelectedCountry,
    setSelectedGroup,
    setTechnicalAssistants,
    setUsers,
    toggleGroupsByCountry,
    updateActiveUserData
} from "./../../../../Store/Actions/chat";


// video-call -- 1
// audio-call -- 2
// screen-share-call -- 3

const buttonSpinnerId = uuid();
const UserHead = (props) => {

    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const windowSize = useWindowSize();
    var supporterId = null;
    if (pathname.includes("/technical-assistant")) {
        supporterId = pathname.split("/")[pathname.split("/").length - 1]
    }
    const userSidebar = useSelector(state => state.layout.userSidebar);
    const user = useSelector(state => state.user.user);
    const translations = useSelector(state => state.translation.translations);
    const connection = useSelector(state => state.signalR.globalConnectionWithRedux);

    const {
        activeUserData,
        activeSessionName,
        users,
        isCallInProcess,
        isCallwaiting,
        clientActiveCallId
    } = useSelector(state => state.chat);

    const {
        activeGroupName,
        activeGroupsName,
        partnerId,
    } = useSelector(state => state.main);

    const [translationService, setTranslationService] = useState(null);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const onOpenUserSidebar = (e) => {
        e.preventDefault();
        userSidebar ? dispatch(closeUserSidebar()) : dispatch(openUserSidebar());
    }

    useEffect(() => {
        if (windowSize && windowSize.width > 1399.98) {
            dispatch(openUserSidebar());
        } else {
            dispatch(closeUserSidebar())
        }
    }, [])

    const closeUserChat = (e) => {
        e.preventDefault();
        var userChat = document.getElementsByClassName("user-chat");
        if (userChat) {
            userChat[0]?.classList.remove("user-chat-show");
            dispatch(setActiveUserData(null));
        }
    }

    const isConnectionOk = () => {
        if (connection && connection.state === "Connected") return true;
        else return false;
    }

    const closeSession = () => {
        AlertService.alertConfirm(
            `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
            "",
            translationService.translate("TR_YES"),
            translationService.translate("TR_NO")
        ).then(() => {
            setButtonSpinner(buttonSpinnerId);
            // ApiService.closeSession(activeUserData.clientSessionKey).then(() => {
            connection.invoke("CloseClientSession", activeUserData.clientSessionKey, user.id).then(() => {
                if (activeSessionName !== "all") {
                    const usersCopy = [...users];
                    const usersCopyWithOutCurrentUser = usersCopy.filter(user => user.clientSessionKey !== activeUserData.clientSessionKey);
                    dispatch(setUsers(usersCopyWithOutCurrentUser));
                    if (usersCopyWithOutCurrentUser.length) {
                        getCurrentUserChat(usersCopyWithOutCurrentUser[0])
                    } else {
                        dispatch(setActiveUserData(null));
                    }
                } else {
                    let today = new Date();
                    const activeUserDataCopy = { ...activeUserData };
                    activeUserDataCopy.endTime = moment(today.toUTCString()).format("L HH:mm");
                    dispatch(updateActiveUserData(activeUserDataCopy));
                }
                dispatch(closeUserSidebar())
                extractButtonSpinner(buttonSpinnerId);
                translationService && AlertService.alert(SUCCESS_KEY, translationService.translate("TR_CLOSED_SUCCESSFULLY"));
            }).catch(error => getFail(error, buttonSpinnerId));
            // }).catch(error => getFail(error, buttonSpinnerId));
        })
    }

    const getCurrentUserChat = (currentUser) => {
        var spinnerId = uuid();
        if (!currentUser) { return false; }

        if (currentUser.supportSessionId) {
            setSpinner(spinnerId);
            ApiService.getCurrentUserChat(currentUser.supportSessionId, 1, 10).then(response => {
                if (response && response.data) {
                    const data = { ...response.data };
                    checkSessionIsAttached(data?.sessionId);
                    let messageResponseModelsItem = [];
                    const messageResponseModels = [...data.messageResponseModels?.pagedData] || [];
                    if (messageResponseModels && messageResponseModels.length) {
                        messageResponseModelsItem = messageResponseModels.filter(message => {
                            if (!message.mediaMessage || (message.mediaMessage && message.mediaMessage.status)) {
                                return message;
                            }
                        })
                    }
                    dispatch(setActiveUserData(currentUser.clientSessionKey));
                    dispatch(addMessagesToUser(currentUser.clientSessionKey, messageResponseModelsItem));
                    dispatch(
                        addFieldsToActiveUserDataFromSession(
                            data.tickets || [],
                            data?.isSessionAttachedMe,
                            data?.attachedUserId,
                            data?.sessionId,
                            data?.feedbackResponse,
                            data?.note,
                            data.sessionSource,
                        )
                    );
                    if (pathname.includes("/dashboard/chats/my-clients") || pathname.includes("/dashboard/chats/all")) {
                        resetScripts();
                        dispatch(getCountries(user.partnerId ? +user.partnerId : +partnerId));
                        _getScriptGroupsWithoutCountry(1);
                    }
                    if (isConnectionOk() && activeGroupName) {
                        if (supporterId) {
                            if (activeUserData && data.isSessionAttachedMe) {
                                if (currentUser && !currentUser.isOnline) {
                                    connection.invoke("ToggleOfflineSessionConnection", currentUser.clientSessionKey,
                                        !activeUserData ? null : activeUserData.isOnline ? null : activeUserData.clientSessionKey)
                                } else {
                                    connection.invoke("ToggleSupervisorSessionConnection", currentUser.clientSessionKey, activeUserData ? activeUserData.clientSessionKey : null)
                                }
                            }
                        } else {
                            if (currentUser && !currentUser.isOnline) {
                                connection.invoke("ToggleOfflineSessionConnection", currentUser.clientSessionKey,
                                    !activeUserData ? null : activeUserData.isOnline ? null : activeUserData.clientSessionKey).catch(error => getFail(error))
                            }
                        }
                    }
                    ApiService.getTicketFields().then(res => {
                        if (res && res.data) {
                            dispatch(setTicketStatusResponse(res.data.ticketStatusResponse));
                            dispatch(setTicketUrgentResponse(res.data.ticketUrgentResponse));
                        }
                    }).catch(error => getFail(error));
                }
                if (user.partnerId || partnerId) {
                    getTechnicalAssistantsList(user.partnerId ? +user.partnerId : +partnerId);
                }
                var userChat = document.getElementsByClassName("user-chat");
                if (userChat) {
                    userChat[0]?.classList.add("user-chat-show");
                }
                document.getElementById("chatInput")?.focus();
                extractSpinner(spinnerId);
            }).catch(error => getFail(error, spinnerId));
        }
    }

    const resetScripts = () => {
        dispatch(setCountries([]));
        dispatch(setSelectedCountry(null));
        dispatch(setScriptGroups([]));
        dispatch(setSelectedGroup(null));
        dispatch(setScripts([]));
        dispatch(toggleGroupsByCountry(false));
    }

    const _getScriptGroupsWithoutCountry = (currentPage) => {
        let data = {
            partnerId: user.partnerId ? +user.partnerId : +partnerId,
            prefix: "",
            pageNumber: currentPage,
            pageSize: fieldLengths.pageSize_10,
        }
        dispatch(getScriptGroups(data));
    }

    const getTechnicalAssistantsList = (partnerId) => {
        ApiService.getTechnicalAssistantsList(partnerId).then(response => {
            if (response && response.data) {
                const data = [...response.data];
                let sortedData = data.sort(function (a, b) { return b.isOnline - a.isOnline });
                dispatch(setTechnicalAssistants(sortedData));
                dispatch(setDefaultTechnicalAssistants(sortedData));
            }
        }).catch(error => getFail(error))
    }

    const checkSessionIsAttached = (sessionId) => {
        if (!sessionId) { return false; }
        if (localStorage.getItem("attachedSessionIds") && MainService.isJson(localStorage.getItem("attachedSessionIds"))) {
            let attachedSessionIds = JSON.parse(localStorage.getItem("attachedSessionIds"));
            attachedSessionIds = attachedSessionIds.filter(item => item !== sessionId);
            localStorage.setItem("attachedSessionIds", JSON.stringify(attachedSessionIds));
        }
    }

    const setSpinner = useCallback(spinner => {
        dispatch(addSpinner(spinner));
    }, []);

    const extractSpinner = useCallback(spinner => {
        dispatch(removeSpinner(spinner));
    }, []);

    const setButtonSpinner = useCallback(spinner => {
        dispatch(addButtonSpinner(spinner));
    }, []);

    const extractButtonSpinner = useCallback(spinner => {
        dispatch(removeButtonSpinner(spinner));
    }, []);

    const getFail = (error, spinnerId) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
        spinnerId && extractButtonSpinner(spinnerId);
        spinnerId && extractSpinner(spinnerId);
    };

    let isDisabledFields = false;
    if (user && (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY)) {
        if (activeGroupsName && activeGroupsName.length && activeGroupName) {
            let isExist = activeGroupsName.find(group => group.groupName === activeGroupName);
            if (!isExist) isDisabledFields = true;
            else isDisabledFields = false;
        } else {
            isDisabledFields = true;
        }
    }

    return translationService ? <React.Fragment>
        <div className="p-3 p-lg-4 border-bottom">
            <Row className="align-items-center">
                <div className='col-9 col-sm-6' >
                    <div className="d-flex align-items-center">
                        {/* <div className="d-block d-lg-none me-2 ms-0">
                            <Link to="#" onClick={(e) => closeUserChat(e)} className="user-chat-remove text-muted font-size-16 p-2">
                                <i className="ri-arrow-left-s-line"></i></Link>
                        </div> */}
                        <div className="me-2 ms-0 session-hide-btn">
                            <Link to="#" onClick={(e) => closeUserChat(e)} className="user-chat-remove text-muted font-size-16 p-2">
                                <i className="ri-arrow-right-s-line"></i></Link>
                        </div>
                        <div className='chat-user-img align-self-center me-3'>
                            {
                                activeUserData ?
                                    activeUserData.profilePicture ?
                                        <div>
                                            <img src={activeUserData.profilePicture} className="rounded-circle avatar-xs" alt="/" />
                                        </div>
                                        : <div>
                                            <div className="avatar-xs">
                                                <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                                    <i className="ri-user-3-line"></i>
                                                </span>
                                            </div>
                                        </div>
                                    : null
                            }
                        </div>
                        <div className="flex-1 overflow-hidden menu-item-wrapper">
                            <h5 className="font-size-16 mb-0 text-truncate">
                                <Link to={"#"} onClick={(e) => onOpenUserSidebar(e)} className="text-reset user-profile-show ml-2">
                                    {
                                        activeUserData ?
                                            activeUserData.clientName ? activeUserData.clientName : activeUserData.clientSessionResponse ? activeUserData.clientSessionResponse.clientName : ""
                                            : null
                                    }
                                    {
                                        activeUserData.isOnline ?
                                            <i className="ri-record-circle-fill font-size-10 text-success d-inline-block mx-2"></i>
                                            : <i className="ri-record-circle-fill font-size-10 d-inline-block mx-2"></i>
                                    }
                                </Link>
                                <small className="chat-user-message text-truncate mb-0 ml-2">
                                    {
                                        activeUserData.isTyping ?
                                            <>
                                                {translationService.translate("TR_TYPING")}
                                                <span className="animate-typing">
                                                    <span className="dot ms-1 dot-dark"></span>
                                                    <span className="dot ms-1 dot-dark"></span>
                                                    <span className="dot ms-1 dot-dark"></span>
                                                </span>
                                            </>
                                            : null
                                    }
                                </small>
                            </h5>
                        </div>
                    </div>

                </div>
                {
                    activeUserData && activeUserData.supporterUserId ?
                        <div className='col-3 col-sm-6' >
                            <ul className="list-inline user-chat-nav text-end mb-0">
                                {/* <li className="list-inline-item">
                                    <Button type="button" color="none" onClick={() => dispatch(setIsMuted(!isMuted))} className="nav-btn user-profile-show">
                                        {
                                            isMuted ? <i className="ri-volume-mute-line"></i> : <i className="ri-volume-up-line"></i>
                                        }
                                    </Button>
                                </li> */}
                                <li className="list-inline-item">
                                    <Button type="button" color="none" onClick={(e) => onOpenUserSidebar(e)} className="nav-btn user-profile-show">
                                        <i className="ri-user-2-line font-size-20"></i>
                                    </Button>
                                </li>
                                <li className="list-inline-item">
                                    {
                                        activeUserData && !activeUserData.endTime && !supporterId && !pathname.includes("inbox") && !userSidebar ?
                                            <ActionButton
                                                type="button"
                                                name={translationService.translate("TR_CLOSE_SESSION")}
                                                className={`
                                                vnd-btn vnd-primary-btn vnd-small-btn m-1 w-auto d-none d-sm-block 
                                                ${isCallInProcess || isCallwaiting || !activeGroupName || isDisabledFields || (clientActiveCallId && clientActiveCallId === activeUserData.clientSessionKey) ? "disabled" : ""}`
                                                }
                                                spinnerId={buttonSpinnerId}
                                                disabled={isCallInProcess || isCallwaiting || !activeGroupName || isDisabledFields ? true : false}
                                                onClick={closeSession}
                                            />
                                            : null
                                    }
                                </li>
                            </ul>
                        </div>
                        :
                        <div className='col-3 col-sm-6' >
                            <ul className="list-inline user-chat-nav text-end mb-0">
                                {/* <li className="list-inline-item">
                                    <Button type="button" color="none" onClick={() => dispatch(setIsMuted(!isMuted))} className="nav-btn user-profile-show">
                                        {
                                            isMuted ? <i className="ri-volume-mute-line"></i> : <i className="ri-volume-up-line"></i>
                                        }
                                    </Button>
                                </li> */}
                                <li className="list-inline-item">
                                    <Button type="button" color="none" onClick={(e) => onOpenUserSidebar(e)} className="nav-btn user-profile-show">
                                        <i className="ri-user-2-line font-size-20"></i>
                                    </Button>
                                </li>
                            </ul>
                        </div>
                }
            </Row>
        </div>
    </React.Fragment>
        : null
}

export default withRouter(UserHead)