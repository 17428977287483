import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { Nav, NavItem, UncontrolledTooltip, Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { setActiveTab, setconversationNameInOpenChat } from "./../../../Store/Actions/layout";
import logo from "./../../../assets/logo/logo-white.png"
import { logout } from '../../../Store/Actions/user';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import { LANGUAGE_KEY, PARTNER_USER_TYPE_ID_KEY, PROJECT_NAME, SUPERVISOR_USER_TYPE_ID_KEY, SUPPORTER_USER_TYPE_ID_KEY } from '../../../Constants/MainKeys';
import { setActiveGroupName, setIsOpenChatLeftMenu, setPartnerId } from '../../../Store/Actions/main';
import { withRouter } from "react-router-dom"
import { setActiveUserData } from '../../../Store/Actions/chat';
import TranslationService from './../../../Services/translationService';
import { getTranslations } from '../../../Store/Actions/translation';
import { setLanguage } from '../../../Store/Actions/language';
import PuffLoader from "react-spinners/PuffLoader";
import useWindowSize from '../../../Hooks/useWindowSize';
import MainService from '../../../Services/mainService';


import LogoImgae from '../../../assets/logo/logo-big-white.png'
import LogoMinImgae from '../../../assets/logo/logo-white.png'
import { RiPushpinLine, RiUnpinLine } from "react-icons/ri";



function LeftSidebarMenu(props) {
    const { pathname } = useLocation()
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.user);
    const { translations } = useSelector(state => state.translation);
    const { languages, language } = useSelector(state => state.language);
    const { subscriptionService, isOpenChatLeftMenu, partnerId } = useSelector(state => state.main);
    const { isShowFlagSpinner } = useSelector(state => state.spinner);

    const { activeUserData } = useSelector(state => state.chat);

    const [translationService, setTranslationService] = useState(null);
    const [dropdownOpen1, setDropdownOpen1] = useState(false);
    const [dropdownOpen2, setDropdownOpen2] = useState(false);
    const windowSize = useWindowSize();

    const toggle1 = () => setDropdownOpen1(!dropdownOpen1);
    const toggle2 = () => setDropdownOpen2(!dropdownOpen2);

    const changeLanguage = isocode => {
        if (!isocode || isocode === language) { return false; }
        localStorage.setItem(LANGUAGE_KEY, isocode);
        setLanguageHandler(isocode);
        const oldIsoCode = pathname.split("/")[1];
        const newPath = pathname.toString().replace(oldIsoCode, `${isocode}`);
        props.history.push(newPath);
        dispatch(getTranslations(true));
    }

    const setLanguageHandler = useCallback(language => {
        dispatch(setLanguage(language));
    }, []);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const logOut = () => {
        dispatch(logout());
        dispatch(setActiveGroupName(null));
        dispatch(setconversationNameInOpenChat("group"));
        dispatch(setPartnerId(null));
        dispatch(setActiveUserData(null));
    }

    if (activeUserData && windowSize.width < 1200) {
        return false
    }

    return translationService ? <React.Fragment>

        <div className={`side-menu flex-lg-column dashboard-menu-wrapper ${isOpenChatLeftMenu ? "open" : ""}`}>


            <div className="navbar-brand-box small-icon">
                <Link to={user && (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) ? `/${language}/dashboard/groups` : `/${language}`} className="logo logo-dark">
                    <span className="logo-sm">
                        <img src={logo} alt="logo" height="30" />
                    </span>
                </Link>

                <Link to={user && (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) ? `/${language}/dashboard/groups` : `/${language}`} className="logo logo-light">
                    <span className="logo-sm">
                        <img src={logo} alt="logo" height="30" />
                    </span>
                </Link>
            </div>
            <div className="dashboard-menu-brand d-none d-xl-block large-icon">
                <div className='d-flex justify-content-between'>
                    <div>
                        <div className="dashboard-menu-brand-name cursor-pointer" onClick={() => props.history.push(`/${language}/widget-settings`)}>
                            <img src={LogoImgae} alt={PROJECT_NAME} />
                        </div>
                        <div className="dashboard-mobile-menu-brand-name">
                            <img src={LogoMinImgae} alt={PROJECT_NAME} />
                        </div>
                    </div>
                    <div>
                        {
                            isOpenChatLeftMenu ?
                                <RiPushpinLine size={20} className='pin' onClick={() => dispatch(setIsOpenChatLeftMenu(false))} />
                                :
                                <RiUnpinLine size={20} className='pin' onClick={() => dispatch(setIsOpenChatLeftMenu(true))} />
                        }
                    </div>
                </div>

            </div>

            <div className="flex-lg-column mb-auto">
                <Nav pills className="side-menu-nav dashboard-menu" role="tablist">
                    {
                        user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY ?
                            <Auxiliary>
                                <NavItem id="Groups" className='dashboard-menu-item'>
                                    <Link
                                        to={`/${language}/dashboard/groups`}
                                        className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/groups") ? "active" : ""}`}>
                                        <i className="ri-group-line"></i>
                                        <span className='link'>{translationService.translate("TR_GROUPS")}</span>
                                    </Link>
                                </NavItem>
                                {
                                    windowSize && windowSize.width > 1199 ? null :
                                        <UncontrolledTooltip target="Groups" className='capitalize' placement="top">
                                            {translationService.translate("TR_GROUPS")}
                                        </UncontrolledTooltip>
                                }
                                <NavItem id="Settings" className='dashboard-menu-item'>
                                    <Link
                                        to={`/${language}/dashboard/settings`}
                                        className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/settings") ? "active" : ""}`}>
                                        <i className="ri-settings-2-line"></i>
                                        <span className='link'>{translationService.translate("TR_SETTINGS")}</span>
                                    </Link>
                                </NavItem>
                                {
                                    windowSize && windowSize.width > 1199 ? null :
                                        <UncontrolledTooltip target="Settings" className='capitalize' placement="top">
                                            {translationService.translate("TR_SETTINGS")}
                                        </UncontrolledTooltip>
                                }
                            </Auxiliary>
                            : null
                    }
                    {
                        user.userTypeId === PARTNER_USER_TYPE_ID_KEY ?
                            <Auxiliary>
                                {/* <NavItem id="WidgetSettings" className='dashboard-menu-item'>
                                    <Link
                                        to={`/${language}/widget-settings`}
                                        className={`dashboard-menu-link nav-link ${pathname.includes("/widget-settings") ? "active" : ""}`}
                                    >
                                        <i className="ri-sound-module-line"></i>
                                    </Link>
                                </NavItem>
                                <UncontrolledTooltip target="WidgetSettings" className='capitalize' placement={windowSize && windowSize.width > 1199 ? "right" : "top"}>
                                    {translationService.translate("TR_WIDGET_SETTINGS")}
                                </UncontrolledTooltip> */}
                                <NavItem id="WidgetSettings" className='dashboard-menu-item'>
                                    <Link
                                        to={`/${language}/widget-settings`}
                                        className={`dashboard-menu-link nav-link ${pathname.includes("/widget-settings") ? "active" : ""}`}
                                    >
                                        <i className="ri-sound-module-line"></i>
                                        <span className='link'>{translationService.translate("TR_WIDGET_SETTINGS")}</span>
                                    </Link>
                                </NavItem>
                                {
                                    windowSize && windowSize.width > 1199 ? null :
                                        <UncontrolledTooltip target="WidgetSettings" className='capitalize' placement="top">
                                            {translationService.translate("TR_WIDGET_SETTINGS")}
                                        </UncontrolledTooltip>
                                }
                                <NavItem id="Chat" className='dashboard-menu-item'>
                                    <Link
                                        to={`/${language}/dashboard/chats/inbox`}
                                        className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/chats") && !pathname.includes("/technical-assistant") ? "active" : ""}`}>
                                        <i className="ri-message-3-line"></i>
                                        <span className='link'>{translationService.translate("TR_CHATBOARD")}</span>
                                    </Link>
                                </NavItem>
                                {
                                    windowSize && windowSize.width > 1199 ? null :
                                        <UncontrolledTooltip target="Chat" className='capitalize' placement="top">
                                            {translationService.translate("TR_CHATBOARD")}
                                        </UncontrolledTooltip>
                                }

                            </Auxiliary>
                            : null
                    }
                    {
                        user.userTypeId === PARTNER_USER_TYPE_ID_KEY || (partnerId && user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) ?
                            <Auxiliary>
                                <NavItem id="TechnicalAssistants" className='dashboard-menu-item'>
                                    <Link
                                        to={`/${language}/dashboard/technical-assistants`}
                                        className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/technical-assistants") ||
                                            pathname.includes("/technical-assistant") ? "active" : ""}`
                                        }
                                    >
                                        <i className="ri-team-line"></i>
                                        <span className='link'>{translationService.translate("TR_TECHNICAL_ASSISTANTS")}</span>
                                    </Link>
                                </NavItem>
                                {
                                    windowSize && windowSize.width > 1199 ? null :
                                        <UncontrolledTooltip target="TechnicalAssistants" className='capitalize' placement="top">
                                            {translationService.translate("TR_TECHNICAL_ASSISTANTS")}
                                        </UncontrolledTooltip>
                                }
                                {
                                    MainService.checkServicePermission(subscriptionService, "helping_scripts_service") ?
                                        <>
                                            <NavItem id="Scripts" className='dashboard-menu-item'>
                                                <Link
                                                    to={`/${language}/dashboard/script-groups`}
                                                    className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/script") ? "active" : ""}`
                                                    }
                                                >
                                                    <i className="ri-chat-new-line"></i>
                                                    <span className='link'>{translationService.translate("TR_SCRIPT_GROUP")}</span>
                                                </Link>
                                            </NavItem>
                                            {
                                                windowSize && windowSize.width > 1199 ? null :
                                                    <UncontrolledTooltip target="Scripts" className='capitalize' placement="top">
                                                        {translationService.translate("TR_SCRIPT_GROUP")}
                                                    </UncontrolledTooltip>
                                            }
                                        </>
                                        : null
                                }
                            </Auxiliary>
                            : null
                    }
                    <Auxiliary>
                        <NavItem id="Tickets" className='dashboard-menu-item'>
                            <Link
                                to={`/${language}/dashboard/tickets`}
                                className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/tickets") ? "active" : ""}`
                                }
                            >
                                <i className="ri-coupon-3-line"></i>
                                <span className='link'>{translationService.translate("TR_TICKETS")}</span>
                            </Link>
                        </NavItem>
                        {
                            windowSize && windowSize.width > 1199 ? null :
                                <UncontrolledTooltip target="Tickets" className='capitalize' placement="top">
                                    {translationService.translate("TR_TICKETS")}
                                </UncontrolledTooltip>
                        }
                    </Auxiliary>
                    <Auxiliary>
                        <NavItem id="Help" className='dashboard-menu-item'>
                            <Link
                                to={`/${language}/dashboard/help`}
                                className={`dashboard-menu-link nav-link ${pathname.includes("/dashboard/help") ? "active" : ""}`
                                }
                            >
                                <i className="ri-question-line"></i>
                                <span className='link'>{translationService.translate("TR_HELP")}</span>
                            </Link>
                        </NavItem>
                        {
                            windowSize && windowSize.width > 1199 ? null :
                                <UncontrolledTooltip target="Help" className='capitalize' placement="top">
                                    {translationService.translate("TR_HELP")}
                                </UncontrolledTooltip>
                        }
                    </Auxiliary>

                    <NavItem id='_Languages' className="profile-user-dropdown btn-group dropup d-flex align-items-center justify-content-center d-xl-none">
                        {
                            windowSize && windowSize.width > 1199 ? null :
                                <UncontrolledTooltip target="_Languages" className='capitalize' placement="top">
                                    {translationService.translate("TR_LANGUAGES")}
                                </UncontrolledTooltip>
                        }
                        <ul className='p-0'>
                            {
                                languages && languages.length ?
                                    <Dropdown nav isOpen={dropdownOpen1} className="btn-group dropup profile-user-dropdown mt-0" toggle={toggle1}>
                                        <DropdownToggle tag="a" className='dashboard-menu-item'>
                                            <div
                                                className={`dashboard-menu-link nav-link`}>
                                                {
                                                    isShowFlagSpinner ?
                                                        <div className='d-flex'>
                                                            <PuffLoader size={30} color={"#6FACE3"} />
                                                        </div>
                                                        : <i className="ri-global-line"></i>
                                                }
                                            </div>
                                        </DropdownToggle>
                                        {
                                            dropdownOpen1 ?
                                                <DropdownMenu className='d-flex flex-column'>
                                                    {
                                                        languages.map(languageItem => {
                                                            return <Link key={languageItem.id} className="py-1" to={"#"} onClick={() => {
                                                                changeLanguage(languageItem.isocode2);
                                                                toggle2()
                                                            }}>
                                                                <img src={languageItem.flagPath} alt="/" className="me-1" height="12" />
                                                                <span className="ms-2 align-middle">{languageItem.name}</span>
                                                            </Link>
                                                        })
                                                    }
                                                </DropdownMenu>
                                                : null
                                        }
                                    </Dropdown>
                                    : null
                            }
                        </ul>
                    </NavItem>
                    <NavItem id='Logout' className="profile-user-dropdown btn-group dropup d-flex align-items-center justify-content-center d-xl-none mt-0">

                        <div className='dashboard-menu-item ssss'>

                            <div
                                className={`dashboard-menu-link nav-link`}
                                onClick={logOut}
                            >
                                <i className="ri-logout-box-r-line"></i>
                                <span className='link'>{translationService.translate("TR_SIGNOUT")}</span>
                            </div>
                        </div>

                        {
                            windowSize && windowSize.width > 1199 ? null :
                                <UncontrolledTooltip target="Logout" className='capitalize' placement="top">
                                    {translationService.translate("TR_SIGNOUT")}
                                </UncontrolledTooltip>
                        }
                    </NavItem>
                </Nav>
            </div>
            <div className="flex-lg-column d-none d-lg-block">
                <Nav className="side-menu-nav justify-content-center dashboard-menu">

                    <NavItem id='Languages'>
                        {
                            windowSize && windowSize.width > 1199 ? null :
                                <UncontrolledTooltip target="Languages" className='capitalize' placement="top">
                                    {translationService.translate("TR_LANGUAGES")}
                                </UncontrolledTooltip>
                        }
                        <ul className='p-0'>
                            {
                                languages && languages.length ?
                                    <Dropdown nav isOpen={dropdownOpen2} className="btn-group dropup w-100" toggle={toggle2}>
                                        <DropdownToggle tag="a" className='dashboard-menu-item w-100'>
                                            <div
                                                className={`dashboard-menu-link nav-link`}>
                                                {
                                                    isShowFlagSpinner ?
                                                        <PuffLoader size={30} color={"#6FACE3"} />
                                                        :
                                                        <i className="ri-global-line"></i>
                                                }
                                                <span className='link'>{translationService.translate("TR_LANGUAGES")}</span>
                                            </div>
                                        </DropdownToggle>
                                        {
                                            dropdownOpen2 ?
                                                <DropdownMenu className='d-flex flex-column'>
                                                    {
                                                        languages.map(languageItem => {
                                                            return <Link key={languageItem.id} className="py-1" to={"#"} onClick={() => {
                                                                changeLanguage(languageItem.isocode2);
                                                                toggle2()
                                                            }}>
                                                                <img src={languageItem.flagPath} alt="/" className="me-1" height="12" />
                                                                <span className="ms-2 align-middle">{languageItem.name}</span>
                                                            </Link>
                                                        })
                                                    }
                                                </DropdownMenu>
                                                : null
                                        }
                                    </Dropdown>
                                    : null
                            }
                        </ul>
                    </NavItem>
                    <NavItem id='Logout'>
                        {
                            windowSize && windowSize.width > 1199 ? null :
                                <UncontrolledTooltip target="Logout" className='capitalize' placement="top">
                                    {translationService.translate("TR_SIGNOUT")}
                                </UncontrolledTooltip>
                        }
                        <div className='dashboard-menu-item'>
                            <div
                                className={`dashboard-menu-link nav-link`}
                                onClick={logOut}
                            >
                                <i className="ri-logout-box-r-line"></i>
                                <span className='link'>{translationService.translate("TR_SIGNOUT")}</span>
                            </div>
                        </div>
                    </NavItem>

                </Nav>
            </div>
        </div>

    </React.Fragment > : null
}

export default withRouter(LeftSidebarMenu);
