import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, withRouter } from "react-router-dom";
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import { useSelector, useDispatch } from 'react-redux';
import uuid from 'react-uuid';
import ApiService from '../../../../Services/ApiService';
import AlertService from '../../../../Services/AlertService';
import { ERROR_KEY, fieldLengths } from '../../../../Constants/MainKeys';
import TranslationService from '../../../../Services/translationService';
import { addSpinner, removeSpinner } from '../../../../Store/Actions/spinner';
import {
    addMessagesToUser,
    addFieldsToActiveUserDataFromSession,
    setActiveUserData,
    setCountries,
    setSelectedCountry,
    setScriptGroups,
    setSelectedGroup,
    setScripts,
    toggleGroupsByCountry,
    getCountries,
    setTechnicalAssistants,
    setDefaultTechnicalAssistants,
    getScriptGroups
} from '../../../../Store/Actions/chat';
import MainService from '../../../../Services/mainService';
import { setTicketStatusResponse, setTicketUrgentResponse } from '../../../../Store/Actions/main';

const OnlineUsers = (props) => {
    const { pathname } = useLocation();
    const [onlineUser, setOnlineUser] = useState(null);

    const responsive = {
        0: { items: 4 },
        1024: { items: 4 },
    }
    var supporterId = null;
    if (pathname.includes("/technical-assistant")) {
        supporterId = pathname.split("/")[pathname.split("/").length - 1]
    }
    const dispatch = useDispatch()
    const { user, users } = useSelector(state => state.user);
    const { spinners } = useSelector(state => state.spinner)
    const partnerId = useSelector(state => state.main.partnerId);
    const activeGroupName = useSelector(state => state.main.activeGroupName);
    const activeUserData = useSelector(state => state.chat.activeUserData);
    const connection = useSelector(state => state.signalR.globalConnectionWithRedux);
    const translations = useSelector(state => state.translation.translations);
    const onlineUsers = users?.sort((x, y) => Number(y.isOnline) - Number(x.isOnline));

    const [translationService, setTranslationService] = useState(null);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    useEffect(() => {
        if (users && users.length) {
            let onlineUser = users.find(user => user.isOnline);
            onlineUser && setOnlineUser(onlineUser)
        }
    }, [users])

    const isConnectionOk = () => {
        if (connection && connection.state === "Connected") return true;
        else return false;
    }

    const getCurrentUserChat = (currentUser, notFirstCall) => {
        var spinnerId = uuid();
        if (!currentUser || spinners.length) { return false; }

        if (notFirstCall) {
            if (currentUser && activeUserData && currentUser.clientSessionKey === activeUserData.clientSessionKey) { return false; }
        }

        if (currentUser.supportSessionId) {
            setSpinner(spinnerId);
            ApiService.getCurrentUserChat(currentUser.supportSessionId, 1, 10).then(response => {
                if (response && response.data) {
                    const data = { ...response.data };
                    checkSessionIsAttached(data?.sessionId);
                    let messageResponseModelsItem = [];
                    const messageResponseModels = [...data.messageResponseModels?.pagedData] || [];
                    if (messageResponseModels && messageResponseModels.length) {
                        messageResponseModelsItem = messageResponseModels.filter(message => {
                            if (!message.mediaMessage || (message.mediaMessage && message.mediaMessage.status)) {
                                return message;
                            }
                        })
                    }
                    dispatch(setActiveUserData(currentUser.clientSessionKey));
                    dispatch(addMessagesToUser(currentUser.clientSessionKey, messageResponseModelsItem));
                    dispatch(
                        addFieldsToActiveUserDataFromSession(
                            data.tickets || [],
                            data?.isSessionAttachedMe,
                            data?.attachedUserId,
                            data?.sessionId,
                            data?.feedbackResponse,
                            data?.note,
                            data.sessionSource,
                        )
                    );
                    if (pathname.includes("/dashboard/chats/my-clients") || pathname.includes("/dashboard/chats/all")) {
                        resetScripts();
                        dispatch(getCountries(user.partnerId ? +user.partnerId : +partnerId));
                        _getScriptGroupsWithoutCountry(1);
                    }
                    if (isConnectionOk() && activeGroupName) {
                        if (supporterId) {
                            if (activeUserData && data.isSessionAttachedMe) {
                                if (currentUser && !currentUser.isOnline) {
                                    connection.invoke("ToggleOfflineSessionConnection", currentUser.clientSessionKey,
                                        !activeUserData ? null : activeUserData.isOnline ? null : activeUserData.clientSessionKey)
                                } else {
                                    connection.invoke("ToggleSupervisorSessionConnection", currentUser.clientSessionKey, activeUserData ? activeUserData.clientSessionKey : null)
                                }
                            }
                        } else {
                            if (currentUser && !currentUser.isOnline) {
                                connection.invoke("ToggleOfflineSessionConnection", currentUser.clientSessionKey,
                                    !activeUserData ? null : activeUserData.isOnline ? null : activeUserData.clientSessionKey).catch(error => getFail(error))
                            }
                        }
                    }
                    ApiService.getTicketFields().then(res => {
                        if (res && res.data) {
                            dispatch(setTicketStatusResponse(res.data.ticketStatusResponse));
                            dispatch(setTicketUrgentResponse(res.data.ticketUrgentResponse));
                        }
                    }).catch(error => getFail(error));
                }
                if (user.partnerId || partnerId) {
                    getTechnicalAssistantsList(user.partnerId ? +user.partnerId : +partnerId);
                }
                var userChat = document.getElementsByClassName("user-chat");
                if (userChat) {
                    userChat[0]?.classList.add("user-chat-show");
                }
                document.getElementById("chatInput")?.focus();
                extractSpinner(spinnerId);
            }).catch(error => getFail(error, spinnerId));
        }
    }

    const resetScripts = () => {
        dispatch(setCountries([]));
        dispatch(setSelectedCountry(null));
        dispatch(setScriptGroups([]));
        dispatch(setSelectedGroup(null));
        dispatch(setScripts([]));
        dispatch(toggleGroupsByCountry(false));
    }

    const _getScriptGroupsWithoutCountry = (currentPage) => {
        let data = {
            partnerId: user.partnerId ? +user.partnerId : +partnerId,
            prefix: "",
            pageNumber: currentPage,
            pageSize: fieldLengths.pageSize_10,
        }
        dispatch(getScriptGroups(data));
    }

    const getTechnicalAssistantsList = (partnerId) => {
        ApiService.getTechnicalAssistantsList(partnerId).then(response => {
            if (response && response.data) {
                const data = [...response.data];
                let sortedData = data.sort(function (a, b) { return b.isOnline - a.isOnline });
                dispatch(setTechnicalAssistants(sortedData));
                dispatch(setDefaultTechnicalAssistants(sortedData));
            }
        }).catch(error => getFail(error))
    }

    const checkSessionIsAttached = (sessionId) => {
        if (!sessionId) { return false; }
        if (localStorage.getItem("attachedSessionIds") && MainService.isJson(localStorage.getItem("attachedSessionIds"))) {
            let attachedSessionIds = JSON.parse(localStorage.getItem("attachedSessionIds"));
            attachedSessionIds = attachedSessionIds.filter(item => item !== sessionId);
            localStorage.setItem("attachedSessionIds", JSON.stringify(attachedSessionIds));
        }
    }

    const setSpinner = useCallback(spinner => {
        dispatch(addSpinner(spinner));
    }, []);

    const extractSpinner = useCallback(spinner => {
        dispatch(removeSpinner(spinner));
    }, []);

    const getFail = (error, spinnerId) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
        spinnerId && extractSpinner(spinnerId);
    };

    return translationService ? <React.Fragment>
        {/* Start user status */}
        {
            onlineUser ?
                <div className="px-4 dot_remove" dir="ltr" >
                    <AliceCarousel
                        responsive={responsive}
                        disableDotsControls={false}
                        disableButtonsControls={false}
                        mouseTracking
                    >
                        {
                            onlineUsers && onlineUsers.length ?
                                onlineUsers.map((user, index) => {
                                    if (user.isOnline) {
                                        return <div key={index} className={`item`} onClick={() => getCurrentUserChat(user)}>
                                            <Link to="#" className="user-status-box">
                                                <div className="avatar-xs mx-auto d-block chat-user-img online">
                                                    <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                                        <i className="ri-user-3-line"></i>
                                                    </span>
                                                    <span className="user-status"></span>
                                                </div>

                                                <h5 className="font-size-13 text-truncate mt-3 mb-1">{user.clientName}</h5>
                                            </Link>
                                        </div>
                                    }
                                })
                                : null
                        }
                    </AliceCarousel>
                    {/* end user status carousel */}
                </div>
                : null
        }
        {/* end user status  */}
    </React.Fragment> : null
}
export default withRouter(OnlineUsers)