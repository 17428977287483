import axios from 'axios';
import AlertService from './AlertService';
import { DEFAULT_LANGUAGE_KEY, ERROR_KEY, LANGUAGE_KEY, OBJECT_KEY, REFRESH_TOKEN_KEY, TOKEN_KEY, USER_KEY, } from '../Constants/MainKeys';


const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL_KEY || "",
  withCredentials: true,
});

const identityApi = axios.create({
  baseURL: process.env.REACT_APP_IDENTITY_API_URL_KEY || "",
});

api.interceptors.request.use(async config => {
  config.headers = {
    'Authorization': localStorage.getItem(TOKEN_KEY) ? localStorage.getItem(TOKEN_KEY) : "",
    'language': localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY,
    "ngrok-skip-browser-warning": true,
  };
  return config;
});

api.interceptors.response.use(response => {
  if (response && response.data && response.data?.status === 500) {
    AlertService.alert("error", "Something went wrong");
  }

  if (response && response.data && response.data.status !== 200) {
    const currentError = response.data;

    //if typeof error === string
    // if (currentError && currentError.message && currentError.message.includes("TR_") && (!currentError.errors || (currentError.errors && !currentError.errors.length))) {
    if (currentError && currentError.message && (!currentError.errors || (currentError.errors && !currentError.errors.length))) {
      return Promise.reject(currentError);
    }

    //if typeof error === Array
    if (currentError.errors && currentError.errors.length && !Object.keys(currentError.errors).length) {
      currentError.errors.forEach(err => {
        if (err.length && err[0] && err[0].key) {
          return Promise.reject(err[0].key);
        }
      });
    }

    //if typeof error === Object
    if (currentError.errors && Object.keys(currentError.errors).length) {
      return Promise.reject(currentError.errors[Object.keys(currentError.errors)[0]][0]?.key);
    }
  }

  if (response && response.data) {
    if (response.data.respmess === 'User is not Authenticated') {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      window.location.href = `/`;
    }
    return !response.data.respcode ? response.data : Promise.reject({ respmess: response.data.respmess, respcode: response.data.respcode });
  }
}, error => {
  if (error.response && error.response.status === 401) {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    const user = localStorage.getItem(USER_KEY);
    const token = localStorage.getItem(TOKEN_KEY);
    if (!refreshToken || !user || !token || !localStorage.getItem("rememberMe")) {
      logout();
    } else {
      getUserDataByRefreshToken(refreshToken, user);
    }
  }
  if (error.response) {
    const currentError = error.response.data;

    //if typeof error === string
    if (currentError && currentError.message && currentError.message.includes("TR_") && (!currentError.errors || (currentError.errors && !currentError.errors.length))) {
      return Promise.reject(currentError);
    }

    //if typeof error === Array
    if (currentError.errors && currentError.errors.length) {
      currentError.errors.forEach(err => {
        if (err.length && err[0] && err[0].key) {
          AlertService.alert(ERROR_KEY, err[0].key);
        }
      });
    }

    //if typeof error === Object
    if (typeof currentError.errors === OBJECT_KEY && Object.keys(currentError.errors).length) {
      return Promise.reject(currentError.errors[Object.keys(currentError.errors)[0]][0]?.key);
    }
  } else {
    return Promise.reject();
  }
});

const logout = () => {
  window.location.href = `/${localStorage.getItem(LANGUAGE_KEY) ? localStorage.getItem(LANGUAGE_KEY) : DEFAULT_LANGUAGE_KEY}`;
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
}

const getUserDataByRefreshToken = (refreshToken, user) => {
  const formData = new FormData();
  formData.append(`refreshToken`, refreshToken);
  ApiService.getUserDataByRefreshToken(formData).then(response => {
    if (response.data && response.data.accessToken) {
      const data = { ...response.data };
      localStorage.setItem(TOKEN_KEY, data?.accessToken);
      localStorage.setItem(REFRESH_TOKEN_KEY, data?.refreshToken);
      localStorage.setItem(USER_KEY, user);
    } else {
      logout();
    }
  }).catch(error => getFail(error));
}

const getFail = (error) => {
  if (error) logout();
}

class ApiService {

  // RefreshToken
  static getUserDataByRefreshToken(data) {
    return identityApi.post(`/identity/renewToken`, data)
  };

  static getAllTranslations() {
    return api.get(`/translation/GetByLanguageIsoCode`);
  };
  static getTransaltionByKey(key) {
    return api.get(`/translation/getbykey/${key}`);
  };
  static getLanguages() {
    return api.get(`/language/getall`);
  };
  static currentUser() {
    return api.get(`/user/current`);
  };
  static registration(data) {
    return api.post(`/user/userRegistration`, data);
  };

  static getInboxUsers(partnerId, pageNumber, pageSize) {
    return api.post(`/supportsession/GetSessions?partnerId=${partnerId}&groupType=0`, { pageNumber, pageSize });
  };
  static getMyUsers(supporterUserId, partnerId, pageNumber, pageSize) {
    return api.post(`/supportsession/GetSessions?partnerId=${partnerId}&groupType=1&supporterUserId=${supporterUserId}`, { pageNumber, pageSize });
  };
  static getAllUsers(supporterUserId, partnerId, pageNumber, pageSize) {
    return api.post(`/supportsession/GetSessions?partnerId=${partnerId}&groupType=2&supporterUserId=${supporterUserId}&`, { pageNumber, pageSize });
  };
  static getCurrentUserChat(supportSessionId, pageNumber, pageSize) {
    // return api.get(`/supportsession/getSessionData/${supportSessionId}`);
    return api.post(`/supportsession/getSessionData?supportSessionId=${supportSessionId}`, { pageNumber, pageSize });
  };
  static sendFiles(data) {
    return api.post(`/file/uploadFileIntoChat`, data);
  };
  static getWidgetData() {
    return api.get(`/widgetConfig/getPartnerWidget`);
  };
  static getWidgetDataByPageStepNumber(stepNumber) {
    return api.get(`/widgetConfig/getByStep?step=${stepNumber}`);
  };
  static becomePartner(data) {
    return api.post(`/widgetConfig/createOrUpdate`, data);
  };
  static becomePartnerComplete() {
    return api.put(`/widgetConfig/complete`);
  };
  static getSupporterCompanies() {
    return api.get(`/partnerSupporter/GetSupporterCompanies`);
  };
  static getGroupName() {
    return api.get(`/partner/GetPartnerKey`);
  };
  static getBecomeSupporterPageDataByPartnerKey(key) {
    return api.get(`/invitation/invite?key=${key}`);
  };
  static supporterSelfRegistration(data) {
    return api.post(`/user/SupporterSelfRegistration`, data);
  };
  static existingUserBecomeASupporter(data) {
    return api.post(`/user/existingUserBecomeASupporter`, data);
  };
  static attachSupporterToNewPartner(data) {
    return api.post(`/user/AttacheSupporterToNewPartner`, data);
  };
  static createInvitation(formData) {
    return api.post(`/invitation/create`, formData);
  };
  static updateUser(data) {
    return api.put(`/user/update`, data);
  };
  static updateUserAvatar(formData) {
    return api.put(`/user/updateAvatar`, formData);
  };
  static removeUserAvatar() {
    return api.delete(`/user/deleteavatar`);
  };
  static removeCompanyLogo(id) {
    return api.delete(`/widgetConfig/deleteLogo/${id}`);
  };
  static updateCompanyLogo(data) {
    return api.put(`/widgetConfig/updateLogo/`, data);
  };
  static getPartnerProfileData() {
    return api.get(`/partner/getPartnerProfile`);
  };
  static getTechnicalAssistants(pageNumber, pageSize, status) {
    return api.post(`/partnerSupporter/getPartnerSupporters?status=${status}`, { pageNumber, pageSize });
  };
  static getTechnicalAssistantsList(partnerId) {
    return api.get(`/partnerSupporter/GetSupportersByPartnerId/${partnerId}`);
  };
  static getTicketFields() {
    return api.get(`/ticket/GetDefaultTicket`);
  };
  static getInvitations(pageNumber, pageSize) {
    return api.post(`/invitation/getPartnerInvitations`, { pageNumber, pageSize });
    // return api.get(`/invitation/getPartnerInvitations?pageNumber=${pageNumber}&pageSize=${pageSize}`);
  };
  static updateWidgetData(data) {
    return api.put(`/widgetConfig/update`, data);
  };
  static getCurrentUserPaymentPackage() {
    return api.get(`/partnerpaymentpackage/GetPartnerSubscription`);
  };
  static closeAccount(data) {
    return api.post(`/???`, data);
  };
  static onChangeRecuringpayment() {
    return api.put(`/partnerPaymentPackage/RecuringPayment`);
  };
  static forgotPassword(email) {
    return api.post(`/user/getRestorePasswordUrl?email=${email}`);
  };
  static updatePassword(data) {
    return api.put(`/user/resetPassword`, data);
  };
  static checkUserByKey(key) {
    return api.get(`/user/accessresetpassword?key=${key}`);
  };
  static changeOldPassword(data) {
    return api.put(`/user/updatePassword`, data);
  };
  static createSupporter(data) {
    return api.post(`/user/createSupporterUser`, data);
  };
  static checkSupporterByKey(key) {
    return api.get(`/invitation/accessbecomesupporter?key=${key}`);
  };
  static getAllPaymentPackages() {
    return api.get(`/paymentPackage/getall`);
  };
  static getPaymentPackageById(id) {
    return api.get(`/paymentpackage/getviewmodelbyid/${id}`);
  };
  static getPaymentSystems() {
    return api.get(`/paymentSystem/getall`);
  };
  static paymentNewSubscription(data) {
    return api.post(`/payment/createNewSubscriptionPayment`, data);
  };
  static paymentNewSupporters(data) {
    return api.post(`/payment/createPaymentForNewSupporters`, data);
  };
  static paymentExtendedMonts(data) {
    return api.post(`/payment/createPaymentForExtendedMonts`, data);
  };
  static checkDomain(data) {
    return api.post(`/widgetConfig/CheckDomainDuplicate`, data);
  };
  static getStripeResponse(status, paymentId) {
    return api.post(`/stripe/${status}?paymentKey=${paymentId}`);
  };
  static getPayPalResponse(status, paymentId) {
    return api.post(`/paypal/${status}?paymentKey=${paymentId}`);
  };
  static sendContactUsFields(data) {
    return api.post(`/contact/create`, data);
  };
  static changeGroupIsChecked(data) {
    return api.put(`/supporterActiveLog/UpdateActivity`, data);
  };
  static createTicket(data) {
    return api.post(`/ticket/create`, data);
  };
  static updateTicket(data) {
    return api.put(`/ticket/update`, data);
  };
  static logout() {
    return api.get(`/????`);
  };
  static getSupporterById(supporterId, partnerId) {
    return api.get(`/partnersupporter/GetSupporterProfile?userId=${supporterId}&partnerId=${partnerId}`);
  };
  static getSupporterDataById(supporterId, partnerId) {
    return api.get(`/partnersupporter/getByUserId?userId=${supporterId}&partnerId=${partnerId}`);
  };
  static closeSession(sessionId) {
    return api.put(`/supportSession/closeSession/${sessionId}`);
  };
  static supporterToSupervisor(supporterUserId) {
    return api.put(`/partnerSupporter/SupporterToSupervisor/${supporterUserId}`);
  };
  static attachChatToSupporter(supporterUserId, sessionId) {
    return api.put(`/supportSession/attacheSupporterToSession?userId=${supporterUserId}&sessionId=${sessionId} `);
  };
  static deleteInvitation(id) {
    return api.delete(`/invitation/delete/${id}`);
  };
  static getFeedbacksByRate(supporterUserId, partnerId, rate) {
    return api.get(`/feedback/getbyrate?userid=${supporterUserId}&partnerid=${partnerId}&rate=${rate}`);
  };
  static getSupporteractivelogs(partnerId, supporterUserId, pageNumber, pageSize) {
    return api.post(`/supporteractivelog/getsupporteractivelogs?partnerid=${partnerId}&supporterUserId=${supporterUserId}`, { pageNumber, pageSize });
  };
  static restorePassword(data) {
    return api.put(`/user/RestorePassword`, data);
  };
  static checkKey(key) {
    return api.get(`/user/accessRestorePassword?key=${key}`);
  };
  static disabledSupporter(supporterUserId, partnerId, bool) {
    return api.put(`/partnersupporter/DisableEnableSupporter?supporterUserId=${supporterUserId}&partnerId=${partnerId}&disable=${bool}`);
  };
  static deleteSupporter(supporterUserId) {
    return api.delete(`/user/delete/${supporterUserId}`);
  };
  static getPaymentHistory(userId, pageNumber, pageSize) {
    return api.post(`/payment/GetHistory?userId=${userId}`, { pageNumber, pageSize });
  };
  static getPaymentPackages() {
    return api.get(`/PaymentPackage/GetAllViewModels`);
  };
  static getPageById(id) {
    return api.get(`/page/get/${id}`);
  };
  static getPageByName(name) {
    return api.get(`/page/GetByName/${name}`);
  };
  static getPageContentById(id) {
    return api.get(`/page/GetContent/${id}`);
  };
  static getPartnerSubscriptionHistory(pageNumber, pageSize) {
    return api.post(`/partnerpaymentpackage/getHistory`, { pageNumber, pageSize });
  };
  static getTechnicalAssistantsPageData() {
    return api.get(`/partnerSupporter/getView`);
  };
  static getSubscriptionService(partnerId) {
    return api.get(`/paymentPackageGlobalService/getSubscriptionService/${partnerId}`);
  };
  static getPartnerWidgetService() {
    return api.get(`/partnerGlobalService/getPartnerDefaultService`);
  };
  static onPartnerWidgetServiceChange(data) {
    return api.post(`/PartnerGlobalService/Switch`, data);
  };
  // static getScriptGroupsByCountry(data) {
  //   // return api.post(`/sessionScriptsGroup/GetByCountry`, data);
  //   return api.post(`/sessionScriptsGroup/GetGroups`, data);
  // };
  static getScriptGroups(data) {
    // return api.post(`/sessionScriptsGroup/GetDefaultGroups`, data);
    return api.post(`/sessionScriptsGroup/GetGroups`, data);
  };

  static getGroupById(id) {
    return api.get(`/sessionScriptsGroup/get/${id}`);
  };
  static createGroup(data) {
    return api.post(`/sessionScriptsGroup/create`, data);
  };
  static updateGroup(data) {
    return api.put(`/sessionScriptsGroup/update`, data);
  };
  static deleteGroup(id) {
    return api.delete(`/sessionScriptsGroup/delete/${id}`);
  };
  static getCountries() {
    return api.get(`/country/getall`);
  };
  static getExistingCountries(partnerId) {
    return api.get(`/sessionScriptsGroup/GetExistingCountries/${partnerId}`);
  };
  static deleteCountry(partnerId, countryId) {
    return api.delete(`/sessionScriptsGroup/DeleteGroupCountry?partnerId=${partnerId}&countryId=${countryId}`);
  };
  static getScriptsByGroupId(data) {
    return api.post(`/sessionScript/GetByGroup`, data);
  };
  static createScript(data) {
    return api.post(`/sessionScript/create`, data);
  };
  static updateScript(data) {
    return api.put(`/sessionScript/update`, data);
  };
  static deleteScript(id) {
    return api.delete(`/sessionScript/delete/${id}`);
  };
  static saveNote(data) {
    return api.put(`/supportSession/addSessionNote`, data);
  };
  static sendError(data) {
    return api.post(`/error/create`, data);
  };

  static googleLogin(data) {
    return api.post(`/user/googleAuth`, data);
  }

}

export default ApiService;
