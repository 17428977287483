import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { setActiveSection } from '../../../Store/Actions/main';
import { ERROR_KEY, NUMBER_KEY } from '../../../Constants/MainKeys';
import AlertService from '../../../Services/AlertService';
import uuid from 'react-uuid';
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner';
import CloseSvg from "./../../../assets/icons/close.svg";
import CheckSvg from "./../../../assets/icons/check_.svg";
import ApiService from './../../../Services/ApiService';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import TranslationService from '../../../Services/translationService';
import Input from "./../../../Components/Inputs/Input";
import ActionButton from '../../../Components/Buttons/ActionButton';
import Parser from 'html-react-parser';
import ContactUs from '../../AuthUserPages/Subscriptions/Components/ContactUs';
import PaymentProcess from '../../AuthUserPages/Subscriptions/Components/PaymentProcess';
import { AiOutlineCheck, AiOutlineLeft } from "react-icons/ai"
import { RxCross2 } from "react-icons/rx"


const buttonSpinnerId = uuid();
const Subscriptions = (props) => {

  const assitentsMaxCount = 10000;
  const dispatch = useDispatch();
  const translations = useSelector(state => state.translation.translations);
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);
  const { paymentPackageId } = props.match.params;

  const [paymentPackageViewResponse, setPaymentPackageViewResponse] = useState(null);
  const [sameTimeSupportersCountsCosts, setSameTimeSupportersCountsCosts] = useState([]);
  const [translationService, setTranslationService] = useState(null);
  const [paymentPackageMonthResponses, setPaymentPackageMonthResponses] = useState([]);
  const [assitentsCount, setAssitentsCount] = useState(1);
  const [paymentPackageCost, setPaymentPackageCost] = useState(null);
  const [allServices, setAllServices] = useState([]);
  const [activePaymentPackageId, setActivePaymentPackageId] = useState(null);
  const [monthCount, setMonthCount] = useState(null);
  const [recurringPayment, setRecurringPayment] = useState(false);
  const [perUserPrice, setPerUserPrice] = useState(null);
  const [totalCost, setTotalCost] = useState(null);
  const [allPaymentPackages, setAllPaymentPackages] = useState([]);
  const [paymentSystems, setPaymentSystems] = useState([]);
  const [paymentSystemId, setPaymentSystemId] = useState(null);
  const [isShowPaymentSystemsPart, setIsShowPaymentSystemsPart] = useState(false);
  const [isShowContactUs, setIsShowContactUs] = useState(false);
  const [discount, setDiscount] = useState(null);
  const [supportersMaxCount, setSupportersMaxCount] = useState(0);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    setActiveSectionName("");
    window.scrollTo({ top: 0, behavior: 'smooth' });
    getPaymentPackage(+paymentPackageId);
    getPaymentPackages();
  }, [])

  const getPaymentPackage = (id) => {
    if (!id) { return false; }
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getPaymentPackageById(id).then(response => {
      setMonthCount(null);
      setAssitentsCount(1);
      if (response && response.data) {
        const data = { ...response.data };
        data.supportersMaxCount && setSupportersMaxCount(data.supportersMaxCount);
        setPaymentPackageViewResponse(data);
        setActivePaymentPackageId(data.id);
        data.cost && setPaymentPackageCost(data.cost);
        data.sameTimeSupportersCountCost && setSameTimeSupportersCountsCosts(data.sameTimeSupportersCountCost);
        data.paymentPackageMonthResponses && setPaymentPackageMonthResponses(data.paymentPackageMonthResponses);
        if (
          data.paymentPackageAllServices &&
          data.paymentPackageAllServices.length &&
          data.paymentPackageActiveServices &&
          data.paymentPackageActiveServices.length
        ) {
          data.paymentPackageAllServices.find(x => {
            data.paymentPackageActiveServices.find(c => {
              if (x.id === c.id) {
                x.isActiveService = true;
              }
            });
          })
          setIsShowContactUs(false);
          setAllServices(data.paymentPackageAllServices);
        }
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const getPaymentPackages = () => {
    const spinnerId = uuid();
    ApiService.getAllPaymentPackages().then(response => {
      if (response && response.data) {
        response.data && setAllPaymentPackages(response.data);
      }
    }).catch(error => getFail(error, spinnerId));
  }

  const showPaymentSystems = () => {
    setButtonSpinner(buttonSpinnerId);
    ApiService.getPaymentSystems().then(response => {
      extractButtonSpinner(buttonSpinnerId)
      if (response.data) {
        const data = [...response.data]
        if (data && data.length) {
          setPaymentSystemId(data[0]?.id);
          setPaymentSystems(data);
        }
        setIsShowPaymentSystemsPart(true);
      }
    }).catch(error => getFail(error, buttonSpinnerId));
  }

  const confirmPayment = () => {
    localStorage.setItem("returnUrl", "subscriptions");
    props.history.push(`/${language}/sign-in`)
  }

  const getPrices = (supportersCount) => {
    if (!supportersCount || !sameTimeSupportersCountsCosts) { return false; }
    var sameTimeSupportersCountsCostsCopy = [...sameTimeSupportersCountsCosts];
    var cost = 0;
    sameTimeSupportersCountsCostsCopy.forEach(item => {
      if (!item.minCount && supportersCount === item.maxCount) {
        cost = item.cost;
      } else if (item.minCount && item.maxCount) {
        if (supportersCount >= item.minCount && supportersCount <= item.maxCount) {
          cost = item.cost;
        }
      } else if (!item.maxCount && supportersCount >= item.minCount) {
        cost = item.cost;
      }
    })
    return cost;
  }

  const costPerSupporterForMonth = (discount) => {
    if (!paymentPackageCost) { return false; }
    let amount = paymentPackageCost;
    if (discount) {
      amount -= (amount * discount) / 100;
    }
    return Number(amount.toFixed(2).toString());
  }

  const costPerSupporterByMont = (discount, monthCount) => {
    if (!paymentPackageCost) { return false; }
    let amount = paymentPackageCost;
    if (discount) {
      amount = (paymentPackageCost - (paymentPackageCost * discount / 100)).toFixed(2);
    }
    amount = amount * assitentsCount * monthCount;
    return amount.toFixed(2);
  }

  const setActiveSectionName = (name) => {
    dispatch(setActiveSection(name))
  }

  const onNumberChange = (event, cb, maxCount = null) => {
    // if (event.target.value.includes("e") || !+event.target.value || event.target.value.includes(".")) {
    // if (+event.target.value.charAt(0) === 0) {
    //   event.target.value = event.target.value.substring(1);
    // }
    // if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) > 0 && Number(event.target.value) < maxCount)) {
    if (event.target.value.includes("e") || event.target.value.includes(".") || event.target.value.includes("-") || event.target.value === "0") {
      cb(1);
      setPaymentPackageCost(getPrices(1));
      setIsShowContactUs(false);
      return false;
    };

    if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) < maxCount)) {
      cb(event.target.value ? +event.target.value : "");

      setPaymentPackageCost(getPrices(+event.target.value));

      if (+event.target.value >= supportersMaxCount) {
        setIsShowContactUs(true);
      } else {
        setIsShowContactUs(false);
        if (monthCount && +event.target.value && +event.target.value >= 1) {
          if (!discount) {
            setTotalCost(getPrices(+event.target.value) * monthCount * +event.target.value);
            setPerUserPrice(getPrices(+event.target.value));
          } else {
            var amount = getPrices(+event.target.value) * monthCount;
            amount -= amount * discount / 100;
            amount = amount * +event.target.value;
            setTotalCost(amount.toFixed(2));
            let perUserPrice = getPrices(+event.target.value);
            perUserPrice -= perUserPrice * discount / 100;
            setPerUserPrice(perUserPrice.toFixed(2));
          }
        }
      }
    }
  };

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
    spinnerId && extractSpinner(spinnerId);
  };

  return translationService ?

    <section className="dashboard top-section customer-profile">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="vnd--aside-title-block">
              <div className="backspace">
                <span
                  className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                  onClick={() => {
                    dispatch(setActiveSection("home"))
                    window.history.back();
                  }}
                >
                  <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                </span>
              </div>
              <span className="slash">|</span>
              <div>
                <p className="vnd--upp-text-22">{translationService.translate("TR_SUBSCRIPTION")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {
            paymentPackageViewResponse ?
              // CURRENT PAYMENT PACKAGE LEFT BLOCK
              <div className="col-lg-4 my-2">
                <div className="vnd--m-block light-gray-background">
                  <div className="d-flex align-items-center justify-content-between customer-sucscription mb-3">
                    <span className="vnd--upp-text-20 vnd--opacity-30">{paymentPackageViewResponse.name ? translationService.translate(`${paymentPackageViewResponse.displayName}`) : ""}</span>
                    <strong className='vnd--text-24'>$ {paymentPackageViewResponse && paymentPackageViewResponse.cost ? paymentPackageViewResponse.cost : 0}</strong>
                  </div>
                  <hr />
                  {
                    sameTimeSupportersCountsCosts && sameTimeSupportersCountsCosts.length && !isShowPaymentSystemsPart ?
                      <Auxiliary>
                        <span className='vnd--upp-text-16 mb-3 d-block'>{translationService.translate("TR_TA_COSTS")}</span>
                        <ul className="vnd--list-info">
                          {
                            sameTimeSupportersCountsCosts.map((sameTimeSupportersCountsCost, index) => {
                              return <Auxiliary key={index}>
                                <li
                                  className={`vnd--list-info-item-wrapper px-2 ${sameTimeSupportersCountsCost.cost === paymentPackageCost ? "active-technical-assistants-cost" : ""}`}
                                >
                                  {
                                    sameTimeSupportersCountsCost.minCount && sameTimeSupportersCountsCost.maxCount ?
                                      <span className="vnd--user-info-item">
                                        {sameTimeSupportersCountsCost.minCount}-{sameTimeSupportersCountsCost.maxCount}
                                      </span> : !sameTimeSupportersCountsCost.minCount ?
                                        <span className="vnd--user-info-item">
                                          {sameTimeSupportersCountsCost.maxCount}
                                        </span> :
                                        <span className="vnd--user-info-item">
                                          &gt;&#x3D; {sameTimeSupportersCountsCost.minCount}
                                        </span>
                                  }
                                  <span className=''>
                                    {
                                      sameTimeSupportersCountsCost.cost ?
                                        <Auxiliary>$ {sameTimeSupportersCountsCost.cost}</Auxiliary>
                                        : null
                                    }
                                    {' '}
                                    {translationService.translate(sameTimeSupportersCountsCost.translation)}
                                  </span>
                                </li>
                              </Auxiliary>
                            })
                          }
                        </ul>
                        <hr />
                      </Auxiliary>
                      : null
                  }
                  <Auxiliary>
                    <AiOutlineCheck
                      className='vnd--text-success font-size-20 mr-2'
                    />
                    <span className='vnd--upp-text-16'>{translationService.translate("TR_WHAT_INCLUDED")}</span>
                  </Auxiliary>
                  <ul className="order-list list-unstyled mt-4 d-flex flex-wrap text-small text-left">
                    {
                      allServices && allServices.length ?
                        allServices.map(service => {
                          if (service.isActiveService) {
                            return <li key={service.id} className="mb-3 capitalize">
                              {translationService.translate(service.displayName)}
                            </li>
                          }
                        })
                        : null
                    }
                  </ul>
                  {
                    allServices && allServices.find(x => !x.isActiveService) ?
                      <Auxiliary>
                        <RxCross2
                          className='vnd--text-dark font-size-20 mr-2 text-danger'
                        />
                        <span className='vnd--upp-text-16'>{translationService.translate("TR_WHAT_EXCLUDED")}</span>
                      </Auxiliary> : null
                  }
                  <ul className="order-list list-unstyled mt-4 d-flex flex-wrap text-small text-left">
                    {
                      allServices && allServices.length ?
                        allServices.map(service => {
                          if (!service.isActiveService) {
                            return <li
                              key={service.id}
                              className="mb-3 text-muted capitalize">
                              {translationService.translate(service.displayName)}
                            </li>
                          }
                        })
                        : null
                    }
                  </ul>

                </div>
              </div>
              : null
          }
          {
            isShowPaymentSystemsPart ?
              paymentSystems && paymentSystems.length ? <div className="col-lg-8 col-md-7 col-12 my-2">
                {/* PAYMENT PACKAGE BUY BLOCK */}
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='vnd--m-block light-brand-background h-100'>
                      <div className="backspace">
                        <span
                          className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                          onClick={() => setIsShowPaymentSystemsPart(false)}
                        >
                          <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                        </span>
                      </div>
                      <hr />
                      <div className='payment-process-wrapper'>
                        <div className="payment-process-info">
                          <ul className="vnd--list-info mb-0">
                            {
                              perUserPrice ?
                                <li className="vnd--list-info-item-wrapper">
                                  <span className=" capitalize">{translationService.translate("TR_PRICE_PER_USER")}:</span>
                                  <span className="vnd--user-info-item font-weight-bold">${perUserPrice}</span>
                                </li>
                                : null
                            }
                            {
                              assitentsCount ?
                                <li className="vnd--list-info-item-wrapper">
                                  <span className="vnd--user-info-item capitalize">{translationService.translate("TR_TECHNICAL_ASSISTANTS")}:</span>
                                  <span className="vnd--user-info-item font-weight-bold">{assitentsCount}</span>
                                </li>
                                : null
                            }
                            {
                              monthCount ?
                                <li className="vnd--list-info-item-wrapper">
                                  <span className="vnd--user-info-item capitalize">{translationService.translate("TR_DURATION")}:</span>
                                  <span className="vnd--user-info-item font-weight-bold">{monthCount} {monthCount === 1 ? translationService.translate("TR_MONTH") : translationService.translate("TR_MONTHS")}</span>
                                </li>
                                : null
                            }

                            {/* <li className="vnd--list-info-item-wrapper">
                                        <span className="vnd--user-info-item capitalize">{translationService.translate("TR_RECURRING_PAYMENT")}:</span>
                                        <span className="vnd--user-info-item font-weight-bold">
                                          {recurringPayment ? translationService.translate("TR_YES") : translationService.translate("TR_NO")}
                                        </span>
                                      </li> */}
                            <li><hr /></li>
                            {
                              totalCost ?
                                <li className="vnd--list-info-item-wrapper">
                                  <span className="vnd--upp-text-16">{translationService.translate("TR_TOTAL")}:</span>
                                  <span className="vnd--upp-text-20">${totalCost}</span>
                                </li>
                                : null
                            }
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className="vnd--m-block vnd--borderd h-100">
                      <PaymentProcess
                        setPaymentSystemId={setPaymentSystemId}
                        paymentSystemId={paymentSystemId}
                        paymentSystems={paymentSystems}
                        perUserPrice={perUserPrice}
                        assitentsCount={assitentsCount}
                        monthCount={monthCount}
                        totalCost={totalCost}
                        recurringPayment={recurringPayment}
                        buttonSpinnerId={buttonSpinnerId}
                        confirmPayment={confirmPayment}
                      />
                    </div>
                  </div>
                </div>
              </div> : null
              : null
          }
          {
            // et hatvacy nra hamara vor ete mardy chuni gnac payment package (arajin angama mtnum)
            paymentPackageMonthResponses && paymentPackageMonthResponses.length ?
              <div className="col-lg-8 my-2">
                <div className="row">
                  {
                    // ALL PAYMENT PACKAGES 
                    !isShowPaymentSystemsPart && allPaymentPackages && allPaymentPackages.length ?
                      <div className="col-12">
                        <div className="vnd--m-block light-brand-background mb-2">
                          <ul className="paymant-package-types">
                            <li className="paymant-package-inactive-types">
                              {
                                allPaymentPackages.map(paymentPackage => {
                                  if (paymentPackage.cost !== 0) {
                                    return <div key={paymentPackage.id} className={`${paymentPackage.id === activePaymentPackageId ? "active-payment-package-name" : ""}`}>
                                      <Link to="#" className="vnd--upp-text-20 vnd--vnd--text-primary" onClick={() => {
                                        if (paymentPackage.id !== activePaymentPackageId) {
                                          getPaymentPackage(paymentPackage.id);
                                        }
                                      }}>
                                        {paymentPackage.name}
                                      </Link>
                                    </div>
                                  }
                                })
                              }
                            </li>
                          </ul>
                        </div>
                      </div>
                      : null
                  }
                  {
                    // SUPPORTERS COUNT INPUT
                    !isShowPaymentSystemsPart ?
                      <div className="col-12 my-3">
                        <div className='d-md-flex align-items-end'>
                          <Input
                            type="number"
                            id="assitentsCount"
                            name="assitentsCount"
                            min="1"
                            value={assitentsCount}
                            onChange={(event) => onNumberChange(event, setAssitentsCount, assitentsMaxCount)}
                          />
                          <div className='ml-3 mb-2'>
                            <label className='d-block m-0 font-bold'>{translationService.translate("TR_TECHNICAL_ASSISTANTS")} {translationService.translate("TR_COUNT")} *</label>
                            <small className='mb-3'>• {translationService.translate("TR_TA_LOGGED_COUNT_MESSAGE").replace("{0}", assitentsCount)}</small>
                          </div>
                        </div>
                        <hr />
                      </div>
                      : null
                  }
                </div>
                {
                  !isShowContactUs ?
                    // MONTHS PART
                    <div className="row">
                      {
                        !isShowPaymentSystemsPart ?
                          <Auxiliary>
                            {
                              <div className="col-12 text-right">
                                <div className="d-flex flex-wrap justify-content-between">
                                  <small>{translationService.translate("TR_PRICE_FOR")} <strong>{assitentsCount}</strong> {translationService.translate("TR_ACTIVE")} {assitentsCount === 1 ? translationService.translate("TR_TECHNICAL_ASSISTANT") : translationService.translate("TR_TECHNICAL_ASSISTANTS")}</small>
                                  <small>{translationService.translate("TR_MONTH_DAYS")}</small>
                                </div>
                              </div>
                            }
                            {
                              paymentPackageMonthResponses.map((paymentPackageMonthResponse, index) => {
                                return <div key={index} className={`col-sm-6 col-12 my-2 ${assitentsCount ? "" : "disabled"}`}>
                                  <div
                                    className={`payment-duration-wrapper vnd--m-block
                                          ${monthCount === paymentPackageMonthResponse.monthCount ? "active-payment-package" : ""}
                                        `}
                                    onClick={() => {
                                      setMonthCount(paymentPackageMonthResponse.monthCount);
                                      setPerUserPrice(costPerSupporterForMonth(paymentPackageMonthResponse.discount));
                                      setTotalCost(costPerSupporterByMont(paymentPackageMonthResponse.discount, paymentPackageMonthResponse.monthCount));
                                      setDiscount(paymentPackageMonthResponse.discount);
                                    }}
                                  >
                                    <div className="payment-duration">{paymentPackageMonthResponse.monthCount}</div>
                                    <div className={`payment-info ${paymentPackageMonthResponse.monthCount === 1 ? 'justify-content-center' : ''}`}>
                                      {
                                        paymentPackageMonthResponse.discount ?
                                          <div className="payment-discount">- {paymentPackageMonthResponse.discount} %</div>
                                          : null
                                      }
                                      <div className="price">
                                        ${costPerSupporterForMonth(paymentPackageMonthResponse.discount)}
                                        <span>{translationService.translate("TR_FOR")} {translationService.translate("TR_PER_MONTH")}</span>
                                      </div>
                                      <div className="total-payment capitalize">
                                        {translationService.translate("TR_PAYMENT")}
                                        <span> ${costPerSupporterByMont(paymentPackageMonthResponse.discount, paymentPackageMonthResponse.monthCount)} </span>
                                        {translationService.translate("TR_FOR")}
                                        <span> {paymentPackageMonthResponse.monthCount} </span>
                                        {translationService.translate("TR_MONTH")}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              })
                            }
                            {
                              monthCount ?
                                <div className="col-12">
                                  <ActionButton
                                    type="button"
                                    name={translationService.translate("TR_CONTINUE")}
                                    className="vnd-btn vnd-primary-btn ml-auto my-2"
                                    spinnerId={buttonSpinnerId}
                                    onClick={() => {
                                      showPaymentSystems(false)
                                    }}
                                  />
                                </div>
                                : null
                            }
                          </Auxiliary>
                          : null
                      }
                    </div>
                    : <>
                      <div className='vnd-block vnd--borderd white-background my-2'>
                        <div className="row mb-3">
                          <div className="col-12">
                            <p className="vnd--upp-text-20 mb-2">{Parser(translationService.translate("TR_CONTACT_US"))}</p>
                            <small>
                              {translationService.translate("TR_SUBSCRIPTION_CONTACT_US_INFO").replace('{0}', supportersMaxCount)}
                            </small>
                          </div>
                        </div>
                        <ContactUs cb={() => {
                          setIsShowContactUs(false);
                        }} />
                      </div>
                    </>
                }
              </div>
              : null
          }
        </div>


      </div>
    </section>
    : null
}

export default Subscriptions;