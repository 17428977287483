import React, { useEffect, useState } from 'react';
import { Card } from "reactstrap";
import { VALID_FILE_TYPES_KEY, VALID_IMAGE_TYPES_KEY } from '../../../Constants/MainKeys';
import Lightbox from 'react-image-lightbox';
import { useSelector } from 'react-redux';
import Auxiliary from './../../../hoc/auxiliary/auxiliary';
import TranslationService from '../../../Services/translationService';

function AttachedFiles(props) {

    const { files } = props;
    const translations = useSelector(state => state.translation.translations);
    const [isOpen, setisOpen] = useState(false);
    const [currentImage, setcurrentImage] = useState(null);
    const [translationService, setTranslationService] = useState(null);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const toggleLightbox = (currentImage) => {
        setisOpen(!isOpen);
        setcurrentImage(currentImage);
    }

    const downloadFile = (path) => {
        console.log(path, "path");
        if (!path) return false;

        window.open(path, '_blank').focus();
    }

    return translationService ? <Auxiliary>
        {
            files.map((file, key) =>
                <Card key={key} className="p-2 border mb-2">
                    <div className="d-flex align-items-center">
                        <div className="avatar-sm me-3 ms-0">

                            {
                                file.fileName && VALID_IMAGE_TYPES_KEY.includes(file.fileName?.split('.').pop()) ?
                                    <div
                                        className="avatar-title bg-soft-primary vnd--text-primary rounded font-size-20 pointer"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => toggleLightbox(file)}
                                    >
                                        <i className="ri-image-fill"></i>
                                    </div>
                                    : null
                            }
                            {
                                file.fileName && VALID_FILE_TYPES_KEY.includes(file.fileName?.split('.').pop()) ?
                                    <div
                                        className="avatar-title bg-soft-primary vnd--text-primary rounded font-size-20"
                                    >
                                        <a
                                            href={file.fileRealPath}
                                            className='client-attached-file'
                                            target="_blank"
                                        >
                                            <i className="ri-file-text-fill"></i>
                                        </a>
                                    </div>
                                    : null
                            }
                        </div>
                        <div className="flex-1 overflow-hidden menu-item-wrapper">
                            <div className="text-left">
                                <h5 className="font-size-14 mb-1">{file.name || file.fileName}</h5>
                                <p className="text-muted font-size-13 mb-0">{file.size}</p>
                            </div>
                        </div>
                        {
                            file.fileRealPath ?
                                <span
                                    className={`text-muted px-1 cursor-pointer`} title={translationService.translate("TR_DOWNLOAD")}
                                    onClick={() => downloadFile(file.fileRealPath)}
                                >
                                    <i className="ri-download-2-line"></i>
                                </span>
                                : null
                        }
                    </div>
                </Card>
            )
        }
        {isOpen && (
            <Lightbox
                mainSrc={currentImage.fileRealPath}
                onCloseRequest={toggleLightbox}
                imageTitle={currentImage.name || currentImage.fileName}
            />
        )}
    </Auxiliary> : null
}

export default AttachedFiles;