import React, { useState, useEffect, useCallback } from 'react';
import facebookIcon from "../../../assets/icons/facebook.svg"
import linkedinIcon from "../../../assets/icons/linkedin.svg"
import youtubeIcon from "../../../assets/icons/youtube.svg"
import twitterIcon from "../../../assets/icons/twitter.svg"
import Input from '../../../Components/Inputs/Input';
import { useSelector, useDispatch } from 'react-redux';
import TranslationService from '../../../Services/translationService';
import Textarea from '../../../Components/Inputs/Textarea';
import { addButtonSpinner, removeButtonSpinner } from '../../../Store/Actions/spinner';
import AlertService from '../../../Services/AlertService';
import ApiService from '../../../Services/ApiService';
import uuid from 'react-uuid';
import { ERROR_KEY, PROJECT_NAME, SUCCESS_KEY, fieldLengths } from '../../../Constants/MainKeys';
import ActionButton from '../../../Components/Buttons/ActionButton';
import { setActiveSection } from '../../../Store/Actions/main';
import { Link } from 'react-router-dom';


const spinnerId = uuid();
const Footer = () => {

  const dispatch = useDispatch();
  const { translations } = useSelector(state => state.translation);
  const { language } = useSelector(state => state.language);
  const [name, setName] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
  const [translationService, setTranslationService] = useState(null);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const onChange = (event, cb, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    cb(event.target.value);
    setIsInvalidSubmit(false);
    return () => {
      setName("");
      setSubject("");
      setEmail("");
      setMessage("");
    }
  }

  const setActiveSectionName = (name) => {
    dispatch(setActiveSection(name))
  }

  const onSubmit = (event) => {
    event && event.preventDefault()
    if (!name.trim().length || !subject.trim().length || !email.trim().length || !message.trim().length) {
      setIsInvalidSubmit(true);
      return false;
    } else {
      setButtonSpinner(spinnerId);
      const form = {
        name,
        subject,
        email,
        message
      }
      ApiService.sendContactUsFields(form).then(() => {
        setName("");
        setSubject("");
        setEmail("");
        setMessage("");
        AlertService.alert(SUCCESS_KEY, translationService.translate("TR_MESSAGE_SENT_SUCCESS"));
        extractButtonSpinner(spinnerId);
      }).catch(error => getFail(error, spinnerId));
    }
  }

  const setButtonSpinner = useCallback(spinner => {
    dispatch(addButtonSpinner(spinner));
  }, []);

  const extractButtonSpinner = useCallback(spinner => {
    dispatch(removeButtonSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && extractButtonSpinner(spinnerId);
  };

  return translationService ? <footer className="dark-background">
    <div className="h-section">
      <div className="container">
        <div className="row">
          <div className="col-md-4 my-3">
            <div id='contact-us' className="footer-wrapper flex-column">
              <strong className="contact-us-title">
                <span className="vnd--text-secondary">
                  {translationService.translate("TR_FEEL_FREE")}</span>
                <br />
                {translationService.translate("TR_GET_IN_TOUCH")}
              </strong>
              <form onSubmit={onSubmit}>
                <Input
                  id="name"
                  type="text"
                  name="name"
                  blockClassName="footer-form-group"
                  value={name}
                  className={`${isInvalidSubmit && !name.trim() ? "error-border-bottom" : ""} *`}
                  placeholder={`${translationService.translate("TR_NAME")}`}
                  required={true}
                  onChange={(event) => onChange(event, setName, fieldLengths.maxLength_100)}
                />
                <Input
                  id="subject"
                  type="text"
                  name="subject"
                  blockClassName="footer-form-group"
                  value={subject}
                  className={`${isInvalidSubmit && !subject.trim() ? "error-border-bottom" : ""} *`}
                  placeholder={`${translationService.translate("TR_SUBJECT")}`}
                  required={true}
                  onChange={(event) => onChange(event, setSubject, fieldLengths.maxLength_200)}
                />
                <Input
                  id="email"
                  type="email"
                  name="email"
                  blockClassName="footer-form-group"
                  value={email}
                  className={`${isInvalidSubmit && !email.trim() ? "error-border-bottom" : ""} *`}
                  placeholder={`${translationService.translate("TR_EMAIL")}`}
                  required={true}
                  onChange={(event) => onChange(event, setEmail, fieldLengths.maxLength_120)}
                />
                <Textarea
                  id="message"
                  htmlFor="message"
                  blockClassName="footer-form-group"
                  value={message}
                  className={`resize-none ${isInvalidSubmit && !message.trim() ? "error-border-bottom" : ""} *`}
                  placeholder={translationService.translate("TR_MESSAGE_PLACEHOLDER")}
                  rows="3"
                  required={true}
                  onChange={(event) => onChange(event, setMessage, fieldLengths.maxLength_2000)}
                />
                <ActionButton
                  type="submit"
                  name={translationService.translate("TR_SEND")}
                  className="vnd-btn vnd-secondary-btn"
                  spinnerId={spinnerId}
                />
              </form>
            </div>
          </div>
          <div className="col-md-4 my-3">
            <div className="footer-wrapper flex-column">
              <ul className="footer-menu list-unstyled">
                <li className="fotter-menu-title">{translationService.translate("TR_CONTACT_US")}</li>
                <li className='mt-3'>TEL: <a href="tel:+1(302)894-8885" className="d-inline">+1(302)894-8885</a></li>
                <li className='mt-3'>Email: <span className='text-initial'>info@relmay.com</span></li>
              </ul>
              <ul className="footer-menu community list-unstyled">
                <li className="fotter-menu-title">{translationService.translate("TR_COMMUNITY")}</li>
                <li>
                  <a href="https://fb.com" target="_blank"><i className="ri-facebook-box-fill"></i></a>
                  <a href="https://linkedin.com" target="_blank"><i className="ri-linkedin-box-fill"></i></a>
                  <a href="https://youtube.com" target="_blank"><i className="ri-youtube-fill"></i></a>
                  <a href="https://twitter.com" target="_blank"><i className="ri-twitter-fill"></i></a>
                </li>
                <li className="mt-2"><small>{translationService.translate("TR_FIND_US_IN_NETWORK")}</small></li>
              </ul>
            </div>
          </div>
          <div className="col-md-4 my-3">
            <div className="footer-wrapper flex-column">
              <ul className="footer-menu service list-unstyled">
                <li className="fotter-menu-title">{translationService.translate("TR_PRODUCTS")}</li>
                <div className='d-flex flex-wrap align-items-center'>
                  {/* <li className='mr-1'><Link to="#" onClick={() => setActiveSectionName("pricing")}>{translationService.translate("TR_PRICING")},</Link></li> */}
                  <li className='mr-1'>
                    <Link to={`/${language}/pricing`}>{translationService.translate("TR_PRICING")},</Link>
                  </li>
                  <li className='mr-1'><Link to="#" onClick={() => setActiveSectionName("service")}>{translationService.translate("TR_SERVICE")}</Link></li>
                </div>
              </ul>
              <ul className="footer-menu service list-unstyled">
                <li className="fotter-menu-title">{translationService.translate("TR_ABOUT")}</li>
                <li><Link to={`/${language}/contact-us`}>{translationService.translate("TR_CONTACT_US")}</Link></li>
              </ul>
              <ul className="footer-menu service list-unstyled">
                <li className="fotter-menu-title">{translationService.translate("TR_LEGAL")}</li>
                <li><Link to={`/${language}/privacy-policy`}>{translationService.translate("TR_PRIVACY_POLICY")},</Link></li>
                <li><Link to={`/${language}/cookies-policy`}>{translationService.translate("TR_COOKIES")},</Link></li>
                <li><Link to={`/${language}/terms-and-conditions`}>{translationService.translate("TR_TERMS")}</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright">
      <small>Copyright © <span>{new Date().getFullYear()}</span> powered by ❤️ {PROJECT_NAME}</small>
    </div>
  </footer> : null
}
export default Footer