import {
  SET_ACTIVE_GROUP_NAME,
  SET_PARTNER_ID,
  SET_ACTIVE_SECTION,
  SET_IS_SHOW_SETTINGS,
  SET_IS_CONNECTION_ALLOW,
  SET_TICKET_STATUS_RESPONSE,
  SET_TICKET_URGENT_RESPONSE,
  SET_ACTIVE_GROUPS_NAME,
  SET_IS_SHOW_ATTACH_TO_SESSION_NOTIFICATION,
  SET_IS_HIDE_ATTACH_TO_SESSION_NOTIFICATION,
  SET_IS_MUTED,
  SET_SUBSCRIPTION_SERVICE,
  SET_IS_OPEN_CHAT_LEFT_MENU,
} from "../../Constants/ReduxKeys";

export function setSubscriptionService(data) {
  return {
    type: SET_SUBSCRIPTION_SERVICE,
    payload: data,
  };
};

export function setActiveSection(name) {
  return {
    type: SET_ACTIVE_SECTION,
    payload: name,
  };
};

export function setActiveGroupName(activeGroupName) {
  return {
    type: SET_ACTIVE_GROUP_NAME,
    payload: activeGroupName,
  };
};

export function setActiveGroupsName(group, isChecked) {
  return {
    type: SET_ACTIVE_GROUPS_NAME,
    payload: { group, isChecked },
  };
};

export function setPartnerId(partnerId) {
  return {
    type: SET_PARTNER_ID,
    payload: partnerId,
  };
};

export function setIsShowSettings(bool) {
  return {
    type: SET_IS_SHOW_SETTINGS,
    payload: bool,
  };
};

export function setIsConnectionAllow(bool) {
  return {
    type: SET_IS_CONNECTION_ALLOW,
    payload: bool,
  };
};

export function setTicketStatusResponse(ticketStatusResponse) {
  return {
    type: SET_TICKET_STATUS_RESPONSE,
    payload: ticketStatusResponse,
  };
};

export function setTicketUrgentResponse(ticketUrgentResponse) {
  return {
    type: SET_TICKET_URGENT_RESPONSE,
    payload: ticketUrgentResponse,
  };
};

export function setShowAttacheToSessionNotification(supporterName, sessionName) {
  return {
    type: SET_IS_SHOW_ATTACH_TO_SESSION_NOTIFICATION,
    payload: { supporterName, sessionName },
  };
};

export function setHideAttacheToSessionNotification(name) {
  return {
    type: SET_IS_HIDE_ATTACH_TO_SESSION_NOTIFICATION,
    payload: name,
  };
};

export function setIsMuted(bool) {
  return {
    type: SET_IS_MUTED,
    payload: bool,
  };
};
export function setIsOpenChatLeftMenu(bool) {
  return {
    type: SET_IS_OPEN_CHAT_LEFT_MENU,
    payload: bool,
  };
};

