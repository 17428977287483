import React, { useState } from 'react';
import { Button, Input, Col, UncontrolledTooltip, Label, Form, FormGroup, ButtonDropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { Picker } from 'emoji-mart'
import { useDispatch, useSelector } from 'react-redux';
import AlertService from '../../../../Services/AlertService';
import { setUsers, setActiveUserData, setSelectedScript } from './../../../../Store/Actions/chat';
import uuid from 'react-uuid';
import MainService from '../../../../Services/mainService';
import {
    ALL_VALID_FILE_TYPES,
    ERROR_KEY,
    VALID_FILE_TYPES_KEY,
    VALID_IMAGE_TYPES_KEY,
    IMAGE_KEY,
    FILE_KEY,
    WARNING_KEY,
    SUPPORTER_USER_TYPE_ID_KEY,
    SUPERVISOR_USER_TYPE_ID_KEY,
    fieldLengths,
} from '../../../../Constants/MainKeys';
import FileSvg from '../../../../Svg/FileSvg';
import Auxiliary from './../../../../hoc/auxiliary/auxiliary';
import Textarea from '../../../../Components/Inputs/Textarea';
import { withRouter } from "react-router-dom";
import TranslationService from '../../../../Services/translationService';
import { useEffect } from 'react';


let typingInterval = null;

function ChatInput(props) {

    const mainService = new MainService();
    const dispatch = useDispatch();

    const maxFilesSize = 3; //MB
    const { spinners } = useSelector(state => state.spinner);
    const { user } = useSelector(state => state.user);
    const connection = useSelector(state => state.signalR.globalConnectionWithRedux);
    const language = useSelector(state => state.language.language);
    const translations = useSelector(state => state.translation.translations);

    const {
        users,
        activeUserData,
        selectedScript
    } = useSelector(state => state.chat);

    const {
        activeGroupName,
        activeGroupsName,
        partnerId,
        subscriptionService,
    } = useSelector(state => state.main);

    const [translationService, setTranslationService] = useState(null);
    const [textMessage, setTextMessage] = useState("");
    const [fileEntities, setFileEntities] = useState([]);
    const [isOpenEmoji, setIsOpenEmoji] = useState(false);
    const [isTyping, setIsTyping] = useState(false);
    const [isDisableSendMessage, setIsDisableSendMessage] = useState(false);

    useEffect(() => {
        setTranslationService(new TranslationService(translations));

        return () => {
            setTranslationService(null);
        }
    }, [translations]);

    useEffect(() => {
        if (selectedScript) {
            setTextMessage(selectedScript.script);
            dispatch(setSelectedScript(null));
        }
    }, [selectedScript])

    const handleChange = (event, cb, maxLength = null) => {
        if (maxLength && maxLength < event.target.value.length) { return; }
        cb(event.target.value);
    }

    const toggle = () => setIsOpenEmoji(!isOpenEmoji);

    const addEmoji = (e, cb, text, maxLength = null) => {
        if (maxLength && maxLength < text.length) { return; }
        let emoji = e.native;
        cb(`${text}${emoji}`);
    }

    const getUploadedFileSizeByMB = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    const uploadFile = async event => {
        const fileEntities = [];
        if (!event.target.files.length) { return; }
        let files = [...event.target.files];
        for (let i in files) {
            const file = files[i];
            const fileName = file.name;
            const lastDotIndex = fileName.lastIndexOf('.');
            const fileExtention = lastDotIndex !== -1 ? fileName.substring(lastDotIndex + 1).toLowerCase() : ' ';
            if (ALL_VALID_FILE_TYPES.includes(fileExtention)) {
                let fileType = null;
                if (VALID_IMAGE_TYPES_KEY.includes(fileExtention)) {
                    fileType = IMAGE_KEY;
                }
                else if (VALID_FILE_TYPES_KEY.includes(fileExtention)) {
                    fileType = FILE_KEY;
                }
                await mainService.readFile(file, ALL_VALID_FILE_TYPES).then(uploadedFile => {
                    fileEntities.push({
                        fileName,
                        fileType,
                        file,
                        uploadedFile,
                        id: uuid(),
                        size: file.size
                    });
                }).catch(error => error && AlertService.alert(ERROR_KEY, translationService.translate("TR_INVALID_FILE_EXC")));
            }
        }
        setFileEntities(fileEntities);
    };

    const typing = () => {
        clearInterval(typingInterval);
        if (!isTyping) {
            setIsTyping(true);
            localStorage.setItem("typing", JSON.stringify({ clientSessionKey: activeUserData.clientSessionKey, id: user.id }));
            if (connection && connection.state === 'Connected') {
                connection.invoke("Typing", activeUserData.clientSessionKey, true, user.id).catch(error => getFail(error));
            } else {
                AlertService.alert(ERROR_KEY, translationService.translate("TR_CONNECTION_LOST"))
            }
        }
        typingInterval = setInterval(() => {
            setIsTyping(false);
            localStorage.removeItem("typing");
            if (connection && connection.state === 'Connected') {
                connection.invoke("Typing", activeUserData.clientSessionKey, false, user.id).catch(error => getFail(error));
            }
            clearInterval(typingInterval);
        }, 3000);
    }

    const onSendMessage = (event, textMessage) => {
        event && event.preventDefault();
        if ((!fileEntities.length && !textMessage.trim().length) || isDisableSendMessage) { return; }
        if (fileEntities.length) {
            var bytes = fileEntities.reduce((acc, obj) => { return acc + obj.size; }, 0);
            if (bytes >= maxFilesSize * 1024 * 1024) {
                AlertService.alert(WARNING_KEY, translationService.translate("TR_WIDGET_IMAGE_SIZE_VALIDATION"))
                return false;
            }
        }
        setIsTyping(false);
        localStorage.removeItem("typing");
        setIsDisableSendMessage(true);
        // props.onSendMessage(fileEntities, textMessage.trim(), setFileEntities, setTextMessage, setIsDisableSendMessage);
        props.onSendMessage(fileEntities, textMessage, setFileEntities, setTextMessage, setIsDisableSendMessage);
    }

    const declineSession = () => {
        if (!activeUserData) { return; }
        // AlertService.alertConfirm(
        //     `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
        //     "",
        //     translationService.translate("TR_YES"),
        //     translationService.translate("TR_NO")
        // ).then(() => {
        const usersCopy = [...users];
        const usersCopyWithOutCurrentUser = usersCopy.filter(user => user.clientSessionKey !== activeUserData.clientSessionKey);
        dispatch(setUsers(usersCopyWithOutCurrentUser));
        if (usersCopyWithOutCurrentUser.length) {
            dispatch(setActiveUserData(usersCopyWithOutCurrentUser[0].clientSessionKey))
        } else {
            dispatch(setActiveUserData(null))
        }
        // })
    }

    const acceptSession = () => {
        debugger
        if (!activeUserData) { return; }
        // AlertService.alertConfirm(
        //     `${translationService.translate("TR_ARE_YOU_SURE")} ?`,
        //     "",
        //     translationService.translate("TR_YES"),
        //     translationService.translate("TR_NO")
        // ).then(() => {
        if (connection && connection.state === "Connected") {
            connection.invoke("AcceptClinetSession", activeUserData.clientSessionKey, user.id, user.partnerId ? +user.partnerId : +partnerId, activeGroupName).then(() => {
                if (activeUserData.supportSessionId) {
                    props.history.push(`/${language}/dashboard/chats/my-clients/${activeUserData.supportSessionId}`);
                } else {
                    props.history.push(`/${language}/dashboard/chats/my-clients`);
                }
            }).catch(error => getFail(error))
        } else {
            AlertService.alert(ERROR_KEY, translationService.translate("TR_CONNECTION_LOST"))
        }
        // })
    }

    const removeUploadedFile = (index) => {
        var fileEntitiesItem = fileEntities.filter((file, i) => i !== index);
        setFileEntities(fileEntitiesItem)
    }

    const getFail = (error) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    }

    let isDisabledFields = false;
    if (user && (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY)) {
        if (activeGroupsName && activeGroupsName.length && activeGroupName) {
            let isExist = activeGroupsName.find(group => group.groupName === activeGroupName);
            if (!isExist) isDisabledFields = true;
            else isDisabledFields = false;
        } else {
            isDisabledFields = true;
        }
    }

    return translationService ? <React.Fragment>
        <div className={`p-3 border-top mb-0 mt-auto ${!activeGroupName || isDisabledFields ? "disabled" : ""}`}>
            {
                activeUserData ?
                    activeUserData.supporterUserId ?
                        <Form onSubmit={event => onSendMessage(event, textMessage)} >
                            <FormGroup>
                                {
                                    fileEntities && fileEntities.length ?
                                        <div className='uploaded-files-block'>
                                            <div className='d-flex uploaded-files-wrapper'>
                                                {
                                                    fileEntities.map((fileData, index) => {
                                                        return <Auxiliary key={index}>
                                                            {
                                                                fileData.uploadedFile && fileData.fileType === IMAGE_KEY ?
                                                                    <div className='uploaded-file-item'>
                                                                        <div className="uploaded-file" style={{ backgroundImage: `url(${fileData.uploadedFile})` }} >
                                                                            <div className='close-icon-block' onClick={() => removeUploadedFile(index)}>
                                                                                <i className="ri-close-line"></i>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            fileData.fileName ?
                                                                                <Auxiliary>
                                                                                    <small className='word-break-break-word d-block mt-1' title={fileData.fileName}>{fileData.fileName}</small>
                                                                                    <small className='word-break-break-word d-block mt-1' title={fileData.size}>{getUploadedFileSizeByMB(fileData.size, 2)}</small>
                                                                                </Auxiliary>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }
                                                            {
                                                                fileData.uploadedFile && fileData.fileType === FILE_KEY ?
                                                                    <div className='uploaded-file-item'>
                                                                        <div className="uploaded-file">
                                                                            <FileSvg />
                                                                            <div className='close-icon-block' onClick={() => removeUploadedFile(index)}>
                                                                                <i className="ri-close-line"></i>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            fileData.fileName ?
                                                                                <Auxiliary>
                                                                                    <small className='word-break-break-word d-block mt-1' title={fileData.fileName}>{fileData.fileName}</small>
                                                                                    <small className='word-break-break-word d-block mt-1' title={fileData.size}>{getUploadedFileSizeByMB(fileData.size, 2)}</small>
                                                                                </Auxiliary>
                                                                                : null
                                                                        }
                                                                    </div>
                                                                    : null
                                                            }
                                                        </Auxiliary>;
                                                    })
                                                }
                                            </div>
                                        </div>
                                        : null
                                }

                                <div className='row'>
                                    <Col className='pr-0'>
                                        <div>
                                            <Textarea
                                                id="chatInput"
                                                rows="3"
                                                value={textMessage}
                                                blockClassName="mb-0"
                                                className="resize-none"
                                                placeholder={`${translationService.translate("TR_ENTER_MESSAGE")}...`}
                                                onChange={(event) => handleChange(event, setTextMessage, fieldLengths.maxLength_1500)}
                                                onKeyPress={(event) => {
                                                    typing();
                                                    if (event.charCode === 13 && !event.shiftKey) {
                                                        onSendMessage(event, textMessage);
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs="auto" className='pl-0'>
                                        <div className="chat-input-links ms-md-2">
                                            <ul className="list-inline mb-0 ms-0">
                                                <li className="list-inline-item">
                                                    <ButtonDropdown className="emoji-dropdown" direction="up" isOpen={isOpenEmoji} toggle={toggle}>
                                                        <DropdownToggle id="emoji" color="link" className="text-decoration-none font-size-16 btn-lg waves-effect">
                                                            <i className="ri-emotion-happy-line"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu className="dropdown-menu-end">
                                                            <Picker
                                                                onSelect={(e) => addEmoji(e, setTextMessage, textMessage, fieldLengths.maxLength_1500)}
                                                                include={[
                                                                    "foods",
                                                                    'search',
                                                                    'recent',
                                                                    'people',
                                                                    'nature',
                                                                    'foods',
                                                                    'activity',
                                                                    'places',
                                                                    'objects',
                                                                    'symbols',
                                                                ]}
                                                            />
                                                        </DropdownMenu>
                                                    </ButtonDropdown>
                                                    <UncontrolledTooltip target="emoji" placement="top">
                                                        {translationService.translate("TR_EMOJI")}
                                                    </UncontrolledTooltip>
                                                </li>
                                                {
                                                    MainService.checkServicePermission(subscriptionService, "file_transfer_service") ?
                                                        <li className="list-inline-item input-file mr-2">
                                                            <Label id="files" className="btn btn-link text-decoration-none font-size-16 btn-lg waves-effect">
                                                                <i className="ri-attachment-line"></i>
                                                                <Input
                                                                    onChange={(e) => uploadFile(e)}
                                                                    type="file"
                                                                    name="fileInput"
                                                                    size="60"
                                                                    multiple={true}
                                                                    accept=".jpeg, .png, .pdf, .jpg, .txt, .doc, .docx, .rtf, .xls, .xlsx, .JPEG, .PNG, .JPG, .TXT, .DOC, .DOCX, .RTF, .XLS, .XLSX"
                                                                />
                                                            </Label>
                                                            <UncontrolledTooltip target="files" placement="top">
                                                                {translationService.translate("TR_UPLOAD_FILE")}
                                                            </UncontrolledTooltip>
                                                        </li>
                                                        : null
                                                }
                                                <li className="list-inline-item">
                                                    <Button
                                                        type="submit"
                                                        color="primary"
                                                        className="font-size-16 btn-lg chat-send waves-effect waves-light"
                                                    >
                                                        <i className="ri-send-plane-2-fill"></i>
                                                    </Button>
                                                </li>
                                            </ul>
                                        </div>
                                    </Col>
                                </div>
                            </FormGroup>
                        </Form>
                        :
                        <div className='row'>
                            <div className='col-12 col-md-6 my-md-0 my-1'>
                                <button
                                    className='vnd-btn vnd-small-btn vnd-success-btn w-100'
                                    type="button"
                                    disabled={spinners && spinners.length ? true : false}
                                    onClick={acceptSession}
                                >
                                    {translationService.translate("TR_ACCEPT")}
                                </button>
                            </div>
                            <div className='col-12 col-md-6 my-md-0 my-1 mb-5'>
                                <button
                                    className='vnd-btn vnd-small-btn vnd-danger-btn w-100'
                                    type="button"
                                    disabled={spinners && spinners.length ? true : false}
                                    onClick={() => declineSession()}
                                >
                                    {translationService.translate("TR_DECLINE")}
                                </button>
                            </div>
                        </div>
                    : null
            }
        </div>
    </React.Fragment> : null
}

export default withRouter(ChatInput);