import React, { useState, useRef } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import { ERROR_KEY } from "../../../Constants/MainKeys";
import AlertService from "../../../Services/AlertService";
import ApiService from "../../../Services/ApiService";
import {
  addSpinner,
  removeSpinner,
} from "../../../Store/Actions/spinner"
import Parser from "html-react-parser"
import { useSelector } from "react-redux"
import TranslationService from "../../../Services/translationService"
import { AiOutlineCheck } from "react-icons/ai"
import useWindowSize from "../../../Hooks/useWindowSize";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom"
import NoData from "../../../Components/NoData/NoData";
import Table from 'react-bootstrap/Table';

const Pricing = () => {

  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const [pageData, setPageData] = useState(null);
  const [translationService, setTranslationService] = useState(null);
  const { translations } = useSelector((state) => state.translation);
  const { user } = useSelector(state => state.user);
  const { language } = useSelector((state) => state.language);
  const { pageContent, pageContentId, isShowPageContentSpinner } = useSelector((state) => state.pageContent);
  const windowSize = useWindowSize();

  const [paymentPackages, setPaymentPackages] = useState([]);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    getPaymentPackages();
  }, [])

  const getPaymentPackages = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getPaymentPackages().then(response => {
      if (response && response.data) {
        const data = [...response.data];
        setPaymentPackages(data)
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId))
  }

  const setSpinner = useCallback((spinner) => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback((spinner) => {
    dispatch(removeSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert(AlertService.checkMessageType(error.respcode) || ERROR_KEY, error);
    spinnerId && extractSpinner(spinnerId);
  };

  const allServices = [
    ...new Set(
      paymentPackages
        .flatMap((pkg) => pkg.paymentPackageAllServices)
        .map((service) => service.displayName)
    ),
  ];

  // value = 1 (byte)
  // value

  const convertKilobytesToGigabytes = (kilobytes) => {
    const bytesInAGigabyte = 1000 * 1000;
    return kilobytes / bytesInAGigabyte;
  }

  const setContent = (service, paymentPackage) => {
    const pkgService = paymentPackage.paymentPackageAllServices.find(
      (pkgService) => pkgService.displayName === service
    );

    if (!pkgService) {
      return <RxCross2 className="vnd--text-dark font-size-20 mr-2 text-danger" />;
    }

    if (pkgService.valueType === 1) {
      return <span>
        <b>{convertKilobytesToGigabytes(pkgService.value)}</b> {translationService.translate("TR_GB")}</span>
    } else if (pkgService.valueType === 2) {
      return <span>
        <b>{pkgService.value / 60 / 24}</b> {translationService.translate("TR_DAYS")}</span>
    } else if (pkgService.value) {
      return (
        <span>
          <b>{pkgService.value}</b> {translationService.translate("TR_CONCURRENT")} <br /> {translationService.translate(pkgService.displayName)}
        </span>
      );
    }

    if (pkgService.isAvailable) {
      return <AiOutlineCheck className="vnd--text-success font-size-20 mr-2" />;
    } else {
      return <RxCross2 className="vnd--text-dark font-size-20 mr-2 text-danger" />;
    }
  };


  return translationService ? (
    <section className='section' style={{ marginTop: "100px" }}>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='vnd-block vnd--borderd'>
              <h2 className="vnd--text-50">{Parser(translationService.translate("TR_CHOOSE_YOUR_PP"))}</h2>
              <p className="my-3">{translationService.translate("TR_PAYMENT_PACKAGE_TEXT")}</p>
            </div>
          </div>
        </div>
        <div className='row mt-4'>
          <div className="col-12">
            <div className="pricing-table" style={{ maxWidth: "100%", overflowX: "auto" }}>
              <Table bordered hover size="sm" className="text-center mb-0">
                <thead>
                  <tr>
                    <th></th>
                    {paymentPackages.map((paymentPackage, index) => (
                      <th key={index}>
                        <div className="price-card-wrapper p-2 bg-transparent" style={{ minHeight: "10px" }}>
                          {/* <h3>{translationService.translate(`${paymentPackage.displayName}`)}</h3> */}
                          <p className="price-card-name">{translationService.translate(`${paymentPackage.displayName}`)}</p>

                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {/* Строка с ценами */}
                  <tr>
                    <td className="text-capitalize text-muted text-start font-weight-bold">{translationService.translate("TR_MONTHLY_PRICE")}</td>
                    {paymentPackages.map((paymentPackage, index) => (
                      <td key={index}>
                        <div className="price-card-wrapper p-0 bg-transparent" style={{ minHeight: "10px" }}>
                          {paymentPackage.cost === 0 ? (
                            <span className="price-card-price d-block inline-text">{translationService.translate("TR_FREE")}</span>
                          ) : (
                            <span className="price-card-price">${paymentPackage.cost}<span>/ {translationService.translate("TR_MONTH")}</span></span>
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                  {/* Строки с сервисами */}
                  {allServices.map((service, serviceIndex) => (
                    <tr key={serviceIndex}>
                      <td className="capitalize text-start text-muted">{translationService.translate(service)}</td>
                      {/* // const isAvailable = paymentPackage.paymentPackageAllServices.some(
                    //   (pkgService) => pkgService.displayName === service && pkgService.isAvailable
                    // );
                    // return (
                    //   <td key={packageIndex} className={isAvailable ? "text-success" : "text-danger"}>
                    //     {isAvailable ? <AiOutlineCheck
                    //       className='vnd--text-success font-size-20 mr-2'
                    //     /> : <RxCross2 className='vnd--text-dark font-size-20 mr-2 text-danger' />}
                    //   </td>
                    // ); */}
                      {paymentPackages.map((paymentPackage, packageIndex) => {
                        return (
                          <td key={packageIndex}>

                            {setContent(service, paymentPackage)}
                          </td>
                        );
                      })}
                    </tr>
                  ))}

                  {/* Строка с кнопками */}
                  <tr>
                    <td>{translationService.translate("TR_SUBSCRIBE")}</td>
                    {paymentPackages.map((paymentPackage, index) => (
                      <td key={index} className="p-2">
                        {paymentPackage.cost > 0 ? (
                          <Link
                            to={!paymentPackage.cost ? "#" : `/${language}/subscriptions/${paymentPackage.id}`}
                            className={`link-button ${!paymentPackage.cost ? "disabled" : ""} ${index === 2 ? "vnd-btn vnd-secondary-btn" : "vnd-btn vnd-dark-btn"}`}
                          >
                            {translationService.translate("TR_SUBSCRIBE_NOW")}
                          </Link>
                        ) : (
                          <Link to="#" className="link-button vnd-btn vnd-dark-btn disabled">
                            {translationService.translate("TR_SUBSCRIBE_NOW")}
                          </Link>
                        )}
                      </td>
                    ))}
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null;
};


export default withRouter(Pricing)



