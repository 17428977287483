import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, ModalBody } from "reactstrap";
import { Link, withRouter } from 'react-router-dom';
import { ERROR_KEY, fieldLengths, SUCCESS_KEY, SUPERVISOR_USER_TYPE_ID_KEY, SUPPORTER_USER_TYPE_ID_KEY } from '../../../Constants/MainKeys';
import AlertService from '../../../Services/AlertService';
import uuid from 'react-uuid';
import ApiService from '../../../Services/ApiService';
import { addButtonSpinner, addSpinner, removeButtonSpinner, removeSpinner } from '../../../Store/Actions/spinner';
import MainService from '../../../Services/mainService';
import TranslationService from './../../../Services/translationService';
import Auxiliary from '../../../hoc/auxiliary/auxiliary';
import * as moment from "moment";
import NoData from '../../../Components/NoData/NoData';
import CheckBoxInput from '../../../Components/Inputs/CheckBoxInput';
import CloseSvg from "./../../../assets/icons/close.svg"
import { AiOutlineLeft } from "react-icons/ai";
import colored1 from "./../../../assets/images/svg/1-colored.svg";
import colored2 from "./../../../assets/images/svg/2-colored.svg";
import colored3 from "./../../../assets/images/svg/3-colored.svg";
import colored4 from "./../../../assets/images/svg/4-colored.svg";
import colored5 from "./../../../assets/images/svg/5-colored.svg";
import { AiOutlineUser } from "react-icons/ai";
import ReactPaginate from 'react-paginate';

const TechnicalAssistantView = (props) => {

  const { suppoterId } = props.match.params;
  const dispatch = useDispatch();
  const language = useSelector(state => state.language.language);
  const user = useSelector(state => state.user.user);
  const translations = useSelector(state => state.translation.translations);
  const partnerId = useSelector(state => state.main.partnerId);
  const [translationService, setTranslationService] = useState(null);
  const [currentSupporterData, setCurrentSupporterData] = useState(null);
  const [isDisabledCheckBoxButton, setIsDisabledCheckBoxButton] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const [activeEmojiId, setActiveEmojiId] = useState(null);
  const [feedbackAverage, setFeedbackAverage] = useState(null);
  const [spinnerId, setSpinnerId] = useState(null);
  const [supporteractivelogs, setSupporteractivelogs] = useState([]);
  const [activePageNumber, setActivePageNumber] = useState(0);
  const [pagination, setPagination] = useState(null);

  const [emojisArray, setEmojisArray] = useState([
    {
      src: colored1,
      ratingCount: 0,
      rate: 1,
    },
    {
      src: colored2,
      ratingCount: 0,
      rate: 2,
    },
    {
      src: colored3,
      ratingCount: 0,
      rate: 3,
    },
    {
      src: colored4,
      ratingCount: 0,
      rate: 4,
    },
    {
      src: colored5,
      ratingCount: 0,
      rate: 5,
    },
  ]);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {
    if (suppoterId) {
      getSupporterById();
      getSupporteractivelogs(1);
    }
  }, []);

  const getSupporterById = () => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getSupporterById(suppoterId, user.partnerId ? +user.partnerId : +partnerId).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data.feedbackAverage) {
          let emojisArrayCopy = [...emojisArray];
          emojisArrayCopy.forEach(emoji => {
            if (emoji.rate === 1) emoji.ratingCount = data.feedbackAverage?.oneRatingCount;
            if (emoji.rate === 2) emoji.ratingCount = data.feedbackAverage?.twoRatingCount;
            if (emoji.rate === 3) emoji.ratingCount = data.feedbackAverage?.threeRatingCount;
            if (emoji.rate === 4) emoji.ratingCount = data.feedbackAverage?.fourRatingCount;
            if (emoji.rate === 5) emoji.ratingCount = data.feedbackAverage?.fiveRatingCount;
          });
          setEmojisArray(emojisArrayCopy);
          setFeedbackAverage(data.feedbackAverage);
        }

        setCurrentSupporterData(data);
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const getSupporteractivelogs = (currentPage) => {
    const spinnerId = uuid();
    setSpinner(spinnerId);
    ApiService.getSupporteractivelogs(user.partnerId ? +user.partnerId : +partnerId, suppoterId, currentPage, fieldLengths.pageSize_12).then(response => {
      if (response && response.data) {
        const data = { ...response.data };
        if (data.pagedData && data.pagedData.length) {
          setSupporteractivelogs(data.pagedData);
        } else {
          setSupporteractivelogs([]);
        }
        if (data.pagination) {
          setPagination(data.pagination);
        }
      }
      extractSpinner(spinnerId);
    }).catch(error => getFail(error, spinnerId));
  }

  const handlePageClick = (event) => {
    setActivePageNumber(event.selected);
    getSupporteractivelogs(event.selected + 1);
  }

  const getFeedbacksByRate = (rate) => {
    setActiveEmojiId(rate);
    setSpinnerId(rate);
    setFeedbacks([]);
    ApiService.getFeedbacksByRate(currentSupporterData.id, user.partnerId, rate).then(response => {
      if (response && response.data) {
        setFeedbacks(response.data)
      }
      setSpinnerId(null);
    }).catch(error => getFail(error))
  }

  const onCheckBoxChange = (supporterUserId) => {
    setIsDisabledCheckBoxButton(true);
    ApiService.supporterToSupervisor(supporterUserId).then(() => {
      const currentSupporterDataCopy = { ...currentSupporterData };
      if (currentSupporterDataCopy.userTypeId === SUPPORTER_USER_TYPE_ID_KEY) {
        currentSupporterDataCopy.userTypeId = SUPERVISOR_USER_TYPE_ID_KEY;
      } else {
        currentSupporterDataCopy.userTypeId = SUPPORTER_USER_TYPE_ID_KEY
      }
      setCurrentSupporterData(currentSupporterDataCopy);
      setTimeout(() => {
        setIsDisabledCheckBoxButton(false)
      }, 1000);
      AlertService.alert(SUCCESS_KEY, translationService.translate("TR_DATA_SAVED"));
    }).catch(error => getFail(error))
  }

  const closeModal = () => {
    setFeedbacks([]);
    setActiveEmojiId(null);
  }

  const countDuration = (duration) => {
    let minutes = Math.floor(duration);
    let hours = Math.floor(minutes / 60);
    hours = hours % 24;
    minutes = minutes % 60;
    var hoursDisplay = null;
    var minutesDisplay = null;
    if (hours) hoursDisplay = `${hours.toString().length === 1 ? "0" + hours : hours} ${translationService.translate("TR_HOURS")}`
    if (minutes) minutesDisplay = `${minutes.toString().length === 1 ? "0" + minutes : minutes} ${translationService.translate("TR_MINUTES")}`
    return `${hoursDisplay ? hoursDisplay + "," : ""} ${minutesDisplay ? minutesDisplay : ""}`;
  }

  const setSpinner = useCallback(spinner => {
    dispatch(addSpinner(spinner));
  }, []);

  const extractSpinner = useCallback(spinner => {
    dispatch(removeSpinner(spinner));
  }, []);

  const getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    setTimeout(() => { setIsDisabledCheckBoxButton(false) }, 1000);
    spinnerId && extractSpinner(spinnerId);
    props.history.push(`/${language}/technical-assistants`);
    setSpinnerId(null);
  };

  return user && translationService ?
    <Auxiliary>
      {
        feedbacks.length && activeEmojiId ?
          <Modal
            tabIndex="-1"
            size='lg'
            isOpen={true}
            fullscreen="lg"
            centered
          >
            <ModalBody>
              <div className='d-flex align-items-center justify-content-between'>
                <h5 className='m-0'>
                  {translationService.translate("TR_FEEDBACKS")}
                </h5>
                <div className='modal-close-block'>
                  <img
                    src={CloseSvg}
                    alt="/"
                    onClick={closeModal}
                  />
                </div>
              </div>
              <hr />
              <Auxiliary>
                <div className='feedbacks-block'>
                  {
                    feedbacks.map((feedback, index) => {
                      return <div key={index} className='feedback-block border m-2'>
                        <div>
                          <b className='d-block'>{moment(MainService.convertUTCDateToLocalDate(new Date(feedback.createDate))).format("LL HH:mm")}</b>
                          <span className='word-break-break-word'>{feedback.description}</span>
                        </div>
                        <div>
                          {
                            (() => {
                              switch (feedback.rate) {
                                case 1:
                                  return <img src={colored1} alt="/" />
                                case 2:
                                  return <img src={colored2} alt="/" />
                                case 3:
                                  return <img src={colored3} alt="/" />
                                case 4:
                                  return <img src={colored4} alt="/" />
                                case 5:
                                  return <img src={colored5} alt="/" />
                                default:
                                  break
                              }
                            })()
                          }
                        </div>
                      </div>
                    })
                  }
                </div>
              </Auxiliary>
            </ModalBody>
          </Modal>
          : null
      }
      <aside className='right-section'>
        <section className="top-section">
          <div className="container">
            <div className='row'>
              <div className="col-12">
                <div className="vnd--aside-title-block">
                  <div className="backspace">
                    <span
                      className='m-0 font-size-16 d-flex align-items-center cursor-pointer'
                      onClick={() => window.history.back()}
                    >
                      <AiOutlineLeft className='mr-2' /> {translationService.translate("TR_BACK")}
                    </span>
                  </div>
                  <span className="slash">|</span>
                  <p className="vnd--upp-text-22">{translationService.translate("TR_TECHNICAL_ASSISTANT")}</p>
                </div>
              </div>
            </div>
            {
              currentSupporterData ?
                <Auxiliary>
                  <div className='row'>
                    <div className="col-lg-4 col-md-5 col-12">
                      <div className="vnd--m-block light-brand-background">
                        <div className="avatar vnd-customer-edit-avatar">
                          {
                            currentSupporterData.avatarPath ?
                              <div
                                className="vnd-customer-avatar-img vnd-bkg-cover-img"
                                style={{ backgroundImage: `url(${currentSupporterData.avatarPath ? currentSupporterData.avatarPath : null})` }}
                              />
                              : null
                          }
                          {
                            !currentSupporterData.avatarPath ?
                              <div className="d-flex justify-content-center p-4">
                                <div className="avatar-lg">
                                  <span style={{ fontSize: "30px" }} className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                    <i className="ri-user-3-line"></i>
                                  </span>
                                </div>
                              </div>
                              : null
                          }
                        </div>
                        {
                          currentSupporterData.fullName ?
                            <div className='d-block text-center'>
                              <h4>{currentSupporterData.fullName}</h4>
                              {
                                currentSupporterData.userTypeName ?
                                  <span className="vnd--user-info-item">
                                    {translationService.translate(currentSupporterData.userTypeName) ? translationService.translate(currentSupporterData.userTypeName) : currentSupporterData.userTypeName}
                                  </span>
                                  : null
                              }
                            </div>
                            : null
                        }
                        <hr />
                        <div>
                          <p className="vnd--upp-text-16">{translationService.translate("TR_PROFILE")}</p>
                          <ul className="vnd--list-info">
                            {
                              currentSupporterData.email ?
                                <li className="vnd--list-info-item-wrapper">
                                  <span className="vnd--user-info-item capitalize">{translationService.translate("TR_EMAIL")}</span>
                                  <span className="vnd--user-info-item text-right word-break-break-word">
                                    {currentSupporterData.email}
                                  </span>
                                </li>
                                : null
                            }
                            {
                              currentSupporterData.createDate ?
                                <li className="vnd--list-info-item-wrapper">
                                  <span className="vnd--user-info-item capitalize">{translationService.translate("TR_JOIN_DATE")}</span>
                                  <span className="vnd--user-info-item text-right word-break-break-word">
                                    {moment(new Date(currentSupporterData.createDate)).format("LL")}
                                  </span>
                                </li>
                                : null
                            }
                            <li className="vnd--list-info-item-wrapper">
                              <span className="vnd--user-info-item capitalize">{translationService.translate("TR_STATUS")}</span>
                              <span className="vnd--user-info-item text-right word-break-break-word">
                                {currentSupporterData.isActive ? translationService.translate("TR_ACTIVE") : translationService.translate("TR_INACTIVE")}
                              </span>
                            </li>
                          </ul>
                          <hr />
                          <h3 className='vnd--upp-text-16 mb-3'>{translationService.translate("TR_MAKE_SUPERVISOR")}</h3>
                          <CheckBoxInput
                            id="supervisor"
                            htmlFor="supervisor"
                            labelValue={translationService.translate("TR_SUPERVISOR_USERTYPE")}
                            disabled={isDisabledCheckBoxButton}
                            checked={currentSupporterData.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY ? true : false}
                            onChange={() => onCheckBoxChange(currentSupporterData.id)}
                          />
                          <small className='mt-2 d-block'>{translationService.translate("TR_MAKE_SUPERVISOR_INFO")}</small>
                          <hr />
                        </div>
                        <Link
                          to={`/${language}/dashboard/chats/my-clients/technical-assistant/${currentSupporterData.id}`}
                          className="link-button vnd-btn vnd-primary-btn vnd-small-btn w-100 text-center"
                        >
                          {translationService.translate("TR_CHATBOARD")}
                        </Link>
                      </div>
                    </div>
                    <div className="col-lg-8 col-md-7 my-md-0 mt-3">
                      <div className="row">
                        <div className="col-12">
                          <div className="vnd--m-block vnd--borderd">
                            <h3 className='vnd--upp-text-16'>{translationService.translate("TR_TECHNICAL_ASSISTANT")} {translationService.translate("TR_SESSIONS")}</h3>
                            <ul className="vnd--list-info">
                              <li className="vnd--list-info-item-wrapper">
                                <span className="vnd--user-info-item capitalize">{translationService.translate("TR_ALL")}</span>
                                <span className="vnd--user-info-item">
                                  {
                                    currentSupporterData?.allSessions ?
                                      currentSupporterData?.allSessions
                                      : 0
                                  }
                                </span>
                              </li>
                              <li className="vnd--list-info-item-wrapper">
                                <span className="vnd--user-info-item capitalize">{translationService.translate("TR_TODAY")}</span>
                                <span className="vnd--user-info-item">
                                  {
                                    currentSupporterData?.todaysSessions ?
                                      currentSupporterData?.todaysSessions
                                      : 0
                                  }
                                </span>
                              </li>
                              <li className="vnd--list-info-item-wrapper">
                                <span className="vnd--user-info-item capitalize">{translationService.translate("TR_ACTIVE")}</span>
                                <span className="vnd--user-info-item">
                                  {
                                    currentSupporterData?.activeSessions ?
                                      currentSupporterData?.activeSessions
                                      : 0
                                  }
                                </span>
                              </li>
                              <li className="vnd--list-info-item-wrapper">
                                <span className="vnd--user-info-item capitalize">{translationService.translate("TR_LAST")} {translationService.translate("TR_WEEK")}</span>
                                <span className="vnd--user-info-item">
                                  {
                                    currentSupporterData?.lastWeekSessions ?
                                      currentSupporterData?.lastWeekSessions
                                      : 0
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="col-12 my-3">
                          <div className="vnd--m-block vnd--borderd">
                            <div className='d-flex align-items-center'>
                              <h3 className='vnd--upp-text-16 m-0'>{translationService.translate("TR_FEEDBACKS")}</h3>
                              <div>
                                <span className='mx-3'>|</span>
                                <strong className='vnd--upp-text-16 dark-background px-3 py-2 border-radius-10 text-white'>
                                  {feedbackAverage && feedbackAverage.averageRating ? feedbackAverage.averageRating : 0}
                                </strong>
                              </div>
                            </div>
                            <hr />
                            <div className='emojis-block'>
                              {
                                emojisArray.map(emoji => {
                                  return <div
                                    key={emoji.rate}
                                    className={`current-emoji-block border ${!emoji.ratingCount ? "disabled" : ""} ${activeEmojiId === emoji.rate ? "active" : ""}`}
                                    onClick={() => getFeedbacksByRate(emoji.rate)}
                                  >
                                    <Auxiliary>
                                      <small className='vnd--rating-count'>{emoji.ratingCount}</small>
                                      <img className={spinnerId === emoji.rate ? "rotate-360" : ""} src={emoji.src} alt="/" />
                                      <b className='vnd-rating-values'>{emoji.rate}</b>
                                    </Auxiliary>
                                  </div>
                                })
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* schedule part */}
                  {
                    supporteractivelogs && supporteractivelogs.length ?
                      <div className='row'>
                        <div className='col-12'>
                          <hr />
                        </div>
                        {
                          supporteractivelogs.map((supporteractivelog, index) => {
                            if (supporteractivelog && supporteractivelog.supporterActiveLogs && supporteractivelog.supporterActiveLogs.length) {
                              return <div key={index} className="col-lg-3 col-6 my-2">
                                <div className='vnd--m-block vnd--borderd'>
                                  <div>
                                    <div className="text-center">
                                      <h3 className='vnd--upp-text-16 vnd--opacity-30'>{moment(MainService.convertUTCDateToLocalDate(new Date(supporteractivelog.startDate))).format("LL")}</h3>
                                    </div>
                                    {
                                      supporteractivelog.totalMinutes ?
                                        <div className='d-flex justify-content-between mb-2 px-3 py-2 dark-background text-white border-radius-10'>
                                          <small className='capitalize'>{translationService.translate("TR_TOTAL")}:</small>
                                          <small>{countDuration(supporteractivelog.totalMinutes)}</small>
                                        </div>
                                        : null
                                    }
                                    <div className='d-flex justify-content-between'>
                                      <strong>{translationService.translate("TR_PUNCH_IN_TIME")}</strong>
                                      <strong className='text-end'>{translationService.translate("TR_PUNCH_OUT_TIME")}</strong>
                                    </div>
                                    <hr />
                                    <ul className="vnd--list-info schedule-dates-block mb-0">
                                      {
                                        supporteractivelog.supporterActiveLogs.map((activeLogDate, idx) => {
                                          return <li key={idx} className="vnd--list-info-item-wrapper">
                                            <span>
                                              {
                                                activeLogDate.startDate ?
                                                  moment(MainService.convertUTCDateToLocalDate(new Date(activeLogDate.startDate))).format("HH:mm")
                                                  : null
                                              }
                                            </span>
                                            <span>
                                              {
                                                activeLogDate.endDate ?
                                                  moment(MainService.convertUTCDateToLocalDate(new Date(activeLogDate.endDate))).format("HH:mm")
                                                  : null
                                              }
                                            </span>
                                          </li>
                                        })
                                      }
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            }
                          })
                        }
                      </div>
                      : null
                  }
                  {
                    pagination && supporteractivelogs && supporteractivelogs.length && pagination.totalPages > 1 ?
                      <div className='w-h-100 d-flex justify-content-center mt-5'>
                        <ReactPaginate
                          nextLabel={translationService.translate("TR_NEXT")}
                          onPageChange={handlePageClick}
                          pageRangeDisplayed={3}
                          marginPagesDisplayed={2}
                          pageCount={pagination.totalPages}
                          previousLabel={translationService.translate("TR_PREVIOUS")}
                          pageClassName="page-item"
                          pageLinkClassName="page-link"
                          previousClassName="page-item"
                          previousLinkClassName="page-link"
                          nextClassName="page-item"
                          nextLinkClassName="page-link"
                          breakLabel="..."
                          breakClassName="page-item"
                          breakLinkClassName="page-link"
                          containerClassName="pagination"
                          activeClassName="active"
                          renderOnZeroPageCount={null}
                          forcePage={activePageNumber}
                        />
                      </div>
                      : null
                  }
                </Auxiliary>
                : <NoData />
            }
          </div>
        </section>
      </aside >
    </Auxiliary>
    : null
}

export default withRouter(TechnicalAssistantView)