import React, { useState, useEffect, useCallback } from 'react';
import { InputGroup } from "reactstrap";
import { Link, useLocation, withRouter } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "reactstrap";
import SimpleBar from "simplebar-react";
import OnlineUsers from "./OnlineUsers";
import AlertService from '../../../../Services/AlertService';
import { ERROR_KEY, fieldLengths } from '../../../../Constants/MainKeys';
import ApiService from '../../../../Services/ApiService';
import MainService from '../../../../Services/mainService';
import NoData from '../../../../Components/NoData/NoData';
import Auxiliary from '../../../../hoc/auxiliary/auxiliary';
import TranslationService from '../../../../Services/translationService';
import CustomInput from "./../../../../Components/Inputs/Input"
import uuid from 'react-uuid';
import * as moment from "moment";
import ReactCountryFlag from "react-country-flag"
import { setIsMuted, setTicketStatusResponse, setTicketUrgentResponse } from './../../../../Store/Actions/main';
import {
    addMessagesToUser,
    setActiveUserData,
    setUsers,
    setDefaultUsers,
    showOrHideInboxTitleNotification,
    showOrHideMyClientsTitleNotification,
    setActiveSessionName,
    addFieldsToActiveUserDataFromSession,
    setTechnicalAssistants,
    setDefaultTechnicalAssistants,
    setSelectedCountry,
    setSelectedGroup,
    getCountries,
    setCountries,
    setScriptGroups,
    setScripts,
    toggleGroupsByCountry,
    removeCurrentChatAnimation,
    getScriptGroups
} from "./../../../../Store/Actions/chat";
import {
    addSpinner,
    removeSpinner
} from './../../../../Store/Actions/spinner';
import Parser from 'html-react-parser';
import { FaViber } from "react-icons/fa";
import UserChat from '../UserChat/UserChat';
import { useParams } from 'react-router';
import { AiOutlineInfoCircle } from 'react-icons/ai';


const Chats = (props) => {

    const dispatch = useDispatch();
    var supporterId = null;
    const { pathname } = useLocation();
    const { supportSessionId, session } = props.match.params;
    if (pathname.includes("/technical-assistant")) {
        supporterId = pathname.split("/")[pathname.split("/").length - 1]
    }
    const connection = useSelector(state => state.signalR.globalConnectionWithRedux);
    const activeGroupName = useSelector(state => state.main.activeGroupName);
    const language = useSelector(state => state.language.language);
    const translations = useSelector(state => state.translation.translations);
    const user = useSelector(state => state.user.user);
    const partnerId = useSelector(state => state.main.partnerId);
    const isMuted = useSelector(state => state.main.isMuted);
    const {
        users,
        defaultUsers,
        activeUserData,
        isShowInboxTitleNotification,
        isShowMyClientsTitleNotification,
        activeSessionName,
        groupsByCountry
    } = useSelector(state => state.chat);

    const [searchText, setSearchText] = useState("");
    const [translationService, setTranslationService] = useState(null);
    const [supporterData, setSupporterData] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState(null);

    const [currentChatPage, setCurrentChatPage] = useState(1);
    const [chatPagination, setChatPagination] = useState(null);

    const [chatTopSpinner, setChatTopSpinner] = useState(false);


    useEffect(() => {
        setTranslationService(new TranslationService(translations));
    }, [translations]);

    const isConnectionOk = () => {
        if (connection && connection.state === "Connected") return true;
        else return false;
    }

    useEffect(() => {
        const initialize = () => {
            dispatch(setUsers([]));
            dispatch(setActiveUserData(null));
            setChatPagination(null);
            setCurrentPage(1);
            setCurrentChatPage(1);


            if (!supporterId) {
                handleSessionWithoutSupporter(session);
            } else {
                handleSessionWithSupporter(session);
                getSupporterDataById(supporterId);
            }

            dispatch(setActiveSessionName(session));
        };
        const handleSessionWithoutSupporter = (session) => {
            switch (session) {
                case "inbox":
                    getInboxUsers(1);
                    break;
                case "my-clients":
                    getMyUsers(1);
                    break;
                case "all":
                    getAllUsers(1);
                    break;
                default:
                    props.history.push(`/${language}/dashboard/chats/inbox`);
                    break;
            }
        };
        const handleSessionWithSupporter = (session) => {
            switch (session) {
                case "my-clients":
                    getMyUsers(1);
                    break;
                case "all":
                    getAllUsers(1);
                    break;
                default:
                    props.history.push(`/${language}/dashboard/chats/my-clients/technical-assistant/${supporterId}`);
                    break;
            }
        };
        initialize();
        return () => {
            dispatch(setActiveUserData(null));
        };
    }, [session, supporterId]);



    const getChatWithPagination = (pageNumber) => {
        setCurrentPage(pageNumber)
        switch (activeSessionName) {
            case "inbox":
                getInboxUsers(pageNumber);
                break;
            case "my-clients":
                getMyUsers(pageNumber);
                break;
            case "all":
                getAllUsers(pageNumber);
                break;
            default:
                break;
        }
    }

    const getSupporterDataById = (supporterId) => {
        ApiService.getSupporterDataById(+supporterId, user.partnerId ? user.partnerId : partnerId).then(response => {
            if (response && response.data) {
                setSupporterData(response.data);
            }
        }).catch(error => getFail(error))
    }

    const search = (event, cb, maxLength = null) => {
        const defaultUsersCopy = [...defaultUsers];
        if (maxLength && maxLength < event.target.value.length) { return; }
        cb(event.target.value);
        if (event.target.value.length && event.target.value.trim().length) {
            if (defaultUsersCopy && defaultUsersCopy.length) {
                dispatch(setUsers([...defaultUsers].filter(user => user.clientName && user.clientName.toUpperCase().includes(event.target.value.toUpperCase()))))
            }
        } else {
            dispatch(setUsers(defaultUsersCopy))
        }
    }

    const getCurrentUserChat = (currentPage, _currentUser, isShowSpinner = true) => {
        let currentUser = _currentUser ? _currentUser : activeUserData;
        var spinnerId = uuid();
        if (!currentUser || (currentUser && !currentUser.supportSessionId)) { return false; }
        // if (currentUser && activeUserData && currentUser.clientSessionKey === activeUserData.clientSessionKey && isShowSpinner) { return false; }
        if (currentUser && activeUserData && currentUser.clientSessionKey === activeUserData.clientSessionKey && isShowSpinner && !supportSessionId) { return false; }
        if (isShowSpinner) {
            setSpinner(spinnerId);
        } else {
            setChatTopSpinner(true);
        }
        ApiService.getCurrentUserChat(currentUser.supportSessionId, currentPage, fieldLengths.pageSize_10).then(response => {
            if (response && response.data) {
                const data = { ...response.data };
                checkSessionIsAttached(data?.sessionId);
                let messageResponseModelsItem = [];
                const messageResponseModels = [...data.messageResponseModels?.pagedData] || [];
                if (messageResponseModels && messageResponseModels.length) {
                    messageResponseModelsItem = messageResponseModels.filter(message => {
                        if (!message.mediaMessage || (message.mediaMessage && message.mediaMessage.status)) {
                            return message;
                        }
                    })
                }
                if (activeUserData && activeUserData.messages && currentPage !== 1) {
                    messageResponseModelsItem = [...messageResponseModelsItem, ...activeUserData.messages]
                }

                if (data.messageResponseModels?.pagination) {
                    setChatPagination(data.messageResponseModels.pagination);
                }
                dispatch(setActiveUserData(currentUser.clientSessionKey));
                dispatch(addMessagesToUser(currentUser.clientSessionKey, messageResponseModelsItem));
                dispatch(
                    addFieldsToActiveUserDataFromSession(
                        data.tickets || [],
                        data?.isSessionAttachedMe,
                        data?.attachedUserId,
                        data?.sessionId,
                        data?.feedbackResponse,
                        data?.note,
                        data.source,
                    )
                );


                if (currentPage === 1) {
                    if (pathname.includes("/dashboard/chats/my-clients") || pathname.includes("/dashboard/chats/all")) {
                        resetScripts();
                        dispatch(getCountries(user.partnerId ? +user.partnerId : +partnerId));
                        _getScriptGroupsWithoutCountry(1);
                    }
                    if (isConnectionOk() && activeGroupName) {
                        if (supporterId) {
                            if (activeUserData && data.isSessionAttachedMe) {
                                if (currentUser && !currentUser.isOnline) {
                                    connection.invoke("ToggleOfflineSessionConnection", currentUser.clientSessionKey,
                                        !activeUserData ? null : activeUserData.isOnline ? null : activeUserData.clientSessionKey)
                                } else {
                                    connection.invoke("ToggleSupervisorSessionConnection", currentUser.clientSessionKey, activeUserData ? activeUserData.clientSessionKey : null)
                                }
                            }
                        } else {
                            if (currentUser && !currentUser.isOnline) {
                                connection.invoke("ToggleOfflineSessionConnection", currentUser.clientSessionKey,
                                    !activeUserData ? null : activeUserData.isOnline ? null : activeUserData.clientSessionKey).catch(error => getFail(error))
                            }
                        }
                    }
                    ApiService.getTicketFields().then(res => {
                        if (res && res.data) {
                            dispatch(setTicketStatusResponse(res.data.ticketStatusResponse));
                            dispatch(setTicketUrgentResponse(res.data.ticketUrgentResponse));
                        }
                    }).catch(error => getFail(error));

                    if (user.partnerId || partnerId) {
                        getTechnicalAssistantsList(user.partnerId ? +user.partnerId : +partnerId);
                    }

                    var userChat = document.querySelector(".user-chat");
                    if (userChat) {
                        userChat.classList.add("user-chat-show");
                    }
                    document.getElementById("chatInput")?.focus();

                    dispatch(removeCurrentChatAnimation(currentUser.clientSessionKey));

                }
                setCurrentChatPage(currentPage);
            }
        }).catch(error => getFail(error)).finally(() => {
            extractSpinner(spinnerId);
            setChatTopSpinner(false);
        }).finally(() => {
            if (supportSessionId) {
                props.history.push(`/${language}/dashboard/chats/my-clients`)
            }
        });
    }

    const checkSessionIsAttached = (sessionId) => {
        if (!sessionId) { return false };
        if (localStorage.getItem("attachedSessionIds") && MainService.isJson(localStorage.getItem("attachedSessionIds"))) {
            let attachedSessionIds = JSON.parse(localStorage.getItem("attachedSessionIds"));
            attachedSessionIds = attachedSessionIds.filter(item => item !== sessionId);
            localStorage.setItem("attachedSessionIds", JSON.stringify(attachedSessionIds));
        }
    }

    const setInpulseClass = (sessionId) => {
        if (!sessionId) { return ""; }
        if (
            localStorage.getItem("attachedSessionIds") &&
            MainService.isJson(localStorage.getItem("attachedSessionIds")) &&
            JSON.parse(localStorage.getItem("attachedSessionIds")) &&
            JSON.parse(localStorage.getItem("attachedSessionIds")).includes(sessionId)
        ) {
            return "attached-session";
        } else {
            return "";
        }
    }

    const resetScripts = () => {
        dispatch(setCountries([]));
        dispatch(setSelectedCountry(null));
        dispatch(setScriptGroups([]));
        dispatch(setSelectedGroup(null));
        dispatch(setScripts([]));
        dispatch(toggleGroupsByCountry(false));
    }

    const _getScriptGroupsWithoutCountry = (currentPage) => {
        let data = {
            partnerId: user.partnerId ? +user.partnerId : +partnerId,
            prefix: "",
            pageNumber: currentPage,
            pageSize: fieldLengths.pageSize_10,
        }
        dispatch(getScriptGroups(data));
    }

    const getTechnicalAssistantsList = (partnerId) => {
        ApiService.getTechnicalAssistantsList(partnerId).then(response => {
            if (response && response.data) {
                const data = [...response.data];
                let sortedData = data.sort(function (a, b) { return b.isOnline - a.isOnline });
                dispatch(setTechnicalAssistants(sortedData));
                dispatch(setDefaultTechnicalAssistants(sortedData));
            }
        }).catch(error => getFail(error))
    }

    const resetUsers = () => {
        dispatch(setUsers(null));
        dispatch(setDefaultUsers(null));
        dispatch(setActiveUserData(null));
    }

    const getInboxUsers = (currentPage) => {
        const spinnerId = uuid();
        if (user.partnerId || partnerId) {
            setSpinner(spinnerId);
            ApiService.getInboxUsers(user.partnerId ? +user.partnerId : +partnerId, currentPage, fieldLengths.pageSize_20).then(response => {
                if (response && response.data) {
                    const data = { ...response.data };
                    if (data.pagedData && data.pagedData.length) {
                        _setUsers(data.pagedData, currentPage)
                    }
                    if (data.pagination) {
                        setPagination(data.pagination);
                    }
                } else {
                    if (currentPage === 1) {
                        resetUsers();
                    }
                }
                dispatch(showOrHideInboxTitleNotification(false));
                extractSpinner(spinnerId);
            }).catch(error => getFail(error, spinnerId));
        }
    }

    const getMyUsers = (currentPage) => {
        const spinnerId = uuid();
        if (user.partnerId || partnerId) {
            setSpinner(spinnerId);
            ApiService.getMyUsers(supporterId ? +supporterId : user.id, user.partnerId ? +user.partnerId : +partnerId, currentPage, fieldLengths.pageSize_20).then(response => {
                if (response && response.data) {
                    const data = { ...response.data };

                    if (data.pagedData && data.pagedData.length) {
                        _setUsers(data.pagedData, currentPage)


                        if (supportSessionId) {
                            let isExist = data.pagedData.find(el => el.supportSessionId === +supportSessionId);
                            if (isExist) {
                                getCurrentUserChat(1, isExist)
                            }
                        }
                    }
                    if (data.pagination) {
                        setPagination(data.pagination);
                    }
                } else {
                    if (currentPage === 1) {
                        resetUsers();
                    }
                }
                dispatch(showOrHideMyClientsTitleNotification(false));
                extractSpinner(spinnerId);
            }).catch(error => getFail(error, spinnerId));
        }
    }

    const getAllUsers = (currentPage) => {
        const spinnerId = uuid();
        if (user.partnerId || partnerId) {
            setSpinner(spinnerId);
            ApiService.getAllUsers(supporterId ? +supporterId : user.id, user.partnerId ? +user.partnerId : +partnerId, currentPage, fieldLengths.pageSize_20).then(response => {
                if (response && response.data) {
                    const data = { ...response.data };
                    if (data.pagedData && data.pagedData.length) {
                        _setUsers(data.pagedData, currentPage)
                    }
                    if (data.pagination) {
                        setPagination(data.pagination);
                    }
                } else {
                    if (currentPage === 1) {
                        resetUsers();
                    }
                }
                extractSpinner(spinnerId);
            }).catch(error => getFail(error, spinnerId));
        }
    }

    const _setUsers = (pagedData, currentPage) => {
        // let updatedUsers = [];
        // let sortedData = pagedData.sort((a, b) => b.isOnline - a.isOnline);
        // if (currentPage === 1) {
        //     updatedUsers = sortedData;
        // } else {
        //     updatedUsers = users.concat(sortedData);
        // }
        // dispatch(setUsers(updatedUsers));
        // dispatch(setDefaultUsers(updatedUsers));

        let updatedUsers = [];
        if (currentPage === 1) {
            updatedUsers = pagedData;
        } else {
            updatedUsers = users.concat(pagedData);
        }
        dispatch(setUsers(updatedUsers));
        dispatch(setDefaultUsers(updatedUsers));
    }

    const setSpinner = useCallback(spinner => {
        dispatch(addSpinner(spinner));
    }, []);

    const extractSpinner = useCallback(spinner => {
        dispatch(removeSpinner(spinner));
    }, []);

    const getFail = (error, spinnerId) => {
        error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
        spinnerId && extractSpinner(spinnerId);
        dispatch(setActiveUserData(null));
        dispatch(setDefaultUsers(null));
    };

    const maxHeight = () => {
        if (window.innerWidth >= 1198.98) {
            return `calc(100vh - ${document.querySelector('aside')?.offsetHeight + 10}px)`
        } else {
            return `calc(100vh - ${document.querySelector('aside')?.offsetHeight + 60}px)`
        }
    }

    return translationService ? <Auxiliary>
        {
            // isShowSettings && user && (user.userTypeId === SUPPORTER_USER_TYPE_ID_KEY || user.userTypeId === SUPERVISOR_USER_TYPE_ID_KEY) ?
            //     <Settings />
            //     :
            <div className={`vnd-list-container`}>
                <aside>
                    <div className="p-3 left-bar-header align-items-center">
                        <div className='d-flex align-items-center justify-content-between'>
                            <h4 className="vnd--upp-text-20">{translationService.translate("TR_CHATS")}</h4>
                            <div className='user-chat-nav ml-2'>
                                <Button type="button" color="none" onClick={() => dispatch(setIsMuted(!isMuted))} className="nav-btn user-profile-show box-shadow-none">
                                    {
                                        isMuted ? <i className="ri-volume-mute-line"></i> : <i className="ri-volume-up-line"></i>
                                    }
                                </Button>
                            </div>
                        </div>
                        {
                            defaultUsers && defaultUsers.length ?
                                <div className={`search-box chat-search-box`}>
                                    <InputGroup size="lg" className="rounded-lg">
                                        <span className="text-decoration-none text-muted pr-1">
                                            <i className="ri-search-line search-icon font-size-18"></i>
                                        </span>
                                        <CustomInput
                                            id="searchText"
                                            htmlFor="searchText"
                                            type="text"
                                            name="searchText"
                                            placeholder={`${translationService.translate("TR_SEARCH")} ...`}
                                            value={searchText}
                                            onChange={(e) => search(e, setSearchText, fieldLengths.maxLength_100)}
                                        />
                                    </InputGroup>
                                </div>
                                : null
                        }
                    </div>

                    {/* online users */}
                    {
                        isConnectionOk() ?
                            <OnlineUsers />
                            : null
                    }
                    <div className={`w-100`}>
                        <hr className={`${supporterId ? "my-0 mt-2" : "m-0 mb-2"}`} />
                        {
                            supporterId ?
                                <div className='vnd-chat-owner-menu'>
                                    <small>
                                        <i className='capitalize'>
                                            {translationService.translate("TR_MY_CHAT")}
                                        </i>
                                    </small>
                                </div>
                                : null
                        }
                        <div className='px-4 d-flex'>
                            <h5
                                className={`font-size-16 inbox-users-title ${activeSessionName === "inbox" && !supporterId ? "active-users-blobk-title" : ""} d-flex align-items-center`}
                                onClick={() => {
                                    props.history.push(`/${language}/dashboard/chats/inbox`)
                                }}
                            >
                                {translationService.translate("TR_INBOX")}
                                {
                                    isShowInboxTitleNotification ?
                                        <div className='title-notification ml-1'>
                                            <div className='title-notification-item' />
                                        </div>
                                        : null
                                }
                            </h5>
                            <h5
                                className={`font-size-16 my-users-title ${activeSessionName === "my-clients" && !supporterId ? "active-users-blobk-title" : ""} d-flex align-items-center`}
                                onClick={() => { props.history.push(`/${language}/dashboard/chats/my-clients`) }}
                            >
                                {translationService.translate("TR_MY_CLIENTS")}
                                {
                                    isShowMyClientsTitleNotification ?
                                        <div className='title-notification ml-1'>
                                            <div className='title-notification-item' />
                                        </div>
                                        : null
                                }
                            </h5>
                            <h5
                                className={`font-size-16 my-users-title  ${activeSessionName === "all" && !supporterId ? "active-users-blobk-title" : ""} d-flex align-items-center`}
                                onClick={() => {
                                    if (supporterId) {
                                        props.history.push(`/${language}/dashboard/chats/all/technical-assistant/${supporterId}`)
                                    } else {
                                        props.history.push(`/${language}/dashboard/chats/all`)
                                    }
                                }}
                            >
                                {translationService.translate("TR_ARCHIVE")}
                            </h5>
                        </div>
                        {
                            !supporterId ?
                                <hr className="my-2" /> : null
                        }
                    </div>
                    {
                        supporterId ?
                            <div className={`w-100`}>
                                <hr className='m-0' />
                                {
                                    supporterData && supporterData.user ?
                                        <div className='vnd-chat-owner-menu'>
                                            <small>
                                                <i className='capitalize'>
                                                    {translationService.translate("TR_CHAT").replace('{0}', supporterData.user.fullName)}
                                                </i>
                                            </small>
                                        </div>
                                        : null
                                }
                                <div className='px-4 d-flex'>
                                    <h5
                                        className={`font-size-16 my-users-title ${activeSessionName === "my-clients" && supporterId ? "active-users-blobk-title" : ""} d-flex align-items-center`}
                                        onClick={() => { props.history.push(`/${language}/dashboard/chats/my-clients/technical-assistant/${supporterId}`) }}
                                    >
                                        {translationService.translate("TR_ACTIVE")}
                                    </h5>
                                    <h5
                                        className={`font-size-16 my-users-title ${activeSessionName === "all" && supporterId ? "active-users-blobk-title" : ""} d-flex align-items-center`}
                                        onClick={() => { props.history.push(`/${language}/dashboard/chats/all/technical-assistant/${supporterId}`) }}
                                    >
                                        {translationService.translate("TR_ARCHIVE")}
                                    </h5>
                                </div>
                                <hr className='my-2' />
                            </div>
                            : null
                    }
                </aside>
                <div className="px-2 mb-auto">
                    <SimpleBar className="chat-message-list" style={{ maxHeight: maxHeight() }}>
                        <ul className="list-unstyled chat-list chat-user-list" id="chat-list">
                            {
                                !users || (users && !users.length) ?
                                    <div className="vnd--m-block light-brand-background mb-3">
                                        <div className="vnd--info-block">
                                            <AiOutlineInfoCircle />
                                            <p className='m-0'>{translationService.translate("TR_HISTORY_MESSAGE")}</p>
                                        </div>
                                    </div>
                                    : null
                            }
                            {
                                users && users.length ? users.map((item, key) =>
                                    <li
                                        key={key}
                                        id={"conversation_" + key}
                                        className={`cl-chat-wrappers 
                                            ${item.unRead ? "unread" : ""}
                                            ${item.isTyping ? "typing" : ""}
                                            ${item.clientSessionKey && activeUserData && activeUserData.clientSessionKey && item.clientSessionKey === activeUserData.clientSessionKey ? "active" : ""}
                                            ${setInpulseClass(item.supportSessionId)}
                                            ${item.isAnimate ? "attached-session" : ""}
                                        `}
                                    >
                                        <Link
                                            className='cl-chat-wrapper-item'
                                            to={"#"}
                                            onClick={() => getCurrentUserChat(1, item)}
                                        // onClick={() => dispatch(setActiveUserData(chat.clientSessionKey))}
                                        >
                                            <div className="d-md-flex justify-content-between align-items-md-start align-items-center">
                                                <div className="d-flex">
                                                    <div className={"chat-user-img " + `${item.isOnline ? "online" : "away"}` + " align-self-center me-3 ms-0"}>
                                                        {
                                                            item.profilePicture ?
                                                                <img src={item.profilePicture} className="rounded-circle avatar-xs" alt="/" />
                                                                : <div className="avatar-xs">
                                                                    <span className="avatar-title rounded-circle bg-soft-primary vnd--text-primary">
                                                                        <i className="ri-user-3-line"></i>
                                                                    </span>
                                                                </div>
                                                        }
                                                        <span className="user-status"></span>
                                                    </div>

                                                    <div className="flex-1 overflow-hidden menu-item-wrapper overflow-text" style={{ width: "200px" }}>
                                                        <h5 className="text-truncate font-size-15 m-0">{item.clientName ? item.clientName : translationService.translate("TR_NOT_SET")}</h5>
                                                        <p className="chat-user-message text-truncate mb-0 mt-1">
                                                            {
                                                                item.isTyping ?
                                                                    <>
                                                                        {translationService.translate("TR_TYPING")}
                                                                        <span className="animate-typing">
                                                                            <span className="dot ms-1"></span>
                                                                            <span className="dot ms-1"></span>
                                                                            <span className="dot ms-1"></span>
                                                                        </span>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {/* image message */}
                                                                        {
                                                                            item.messages && (item.messages.length > 0 && item.messages[(item.messages).length - 1].isImageMessage === true) ? <i className="ri-image-fill align-middle me-1"></i> : null
                                                                        }
                                                                        {/* file message */}
                                                                        {
                                                                            item.messages && (item.messages.length > 0 && item.messages[(item.messages).length - 1].isFileMessage === true) ? <i className="ri-file-text-fill align-middle me-1"></i> : null
                                                                        }
                                                                        {
                                                                            item.lastMessageResponse && item.lastMessageResponse.message1 && (!item.lastMessageResponse.fileMessages || (item.lastMessageResponse.fileMessages && !item.lastMessageResponse.fileMessages.length))
                                                                                ? translationService.translate(Parser(item.lastMessageResponse.message1))
                                                                                    ? translationService.translate(Parser(item.lastMessageResponse.message1))
                                                                                    : Parser(item.lastMessageResponse.message1)
                                                                                : null
                                                                        }
                                                                        {
                                                                            item.lastMessageResponse && ((item.lastMessageResponse.fileMessages && item.lastMessageResponse.fileMessages.length) || item.lastMessageResponse.filePath) ? <i className="ri-image-fill"></i> : null
                                                                        }
                                                                    </>
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className='d-sm-flex justify-content-between align-items-start w-100'>
                                                    <ul className="vnd--list-group-horizontal d-sm-flex ms-md-3 w-auto">
                                                        {
                                                            item.lastMessageResponse && item.lastMessageResponse.createDate ?
                                                                <li style={{ width: "50px" }}>
                                                                    <small className='d-block'>
                                                                        {
                                                                            item.lastMessageResponse.localDate ?
                                                                                moment(item.lastMessageResponse.createDate).format("HH:mm")
                                                                                : moment(MainService.convertUTCDateToLocalDate(new Date(item.lastMessageResponse.createDate))).format("HH:mm")
                                                                        }
                                                                    </small>
                                                                    {
                                                                        item.unreadMessagesCount ?
                                                                            <div className="unread-message" id={"unRead" + item.id}>
                                                                                <span className="badge badge-soft-danger rounded-pill">{item.unreadMessagesCount}</span>
                                                                            </div>
                                                                            : null
                                                                    }
                                                                </li>
                                                                :
                                                                <li style={{ width: "50px" }}>
                                                                    <small className='d-block'>
                                                                        <span>{translationService.translate("TR_NOT_SET")}</span>
                                                                    </small>
                                                                </li>
                                                        }
                                                        <li className="ms-sm-5 ms-0">
                                                            <small className='d-block'>{translationService.translate("TR_CHANNEL")}</small>
                                                            <span className="text-md-center d-block">
                                                                {
                                                                    (() => {
                                                                        switch (item.source) {
                                                                            case 0:
                                                                                return <i className="ri-message-3-fill font-size-22" title="Widget"></i>;
                                                                            case 1:
                                                                                return <i className="ri-facebook-box-fill font-size-22" title="Facebook"></i>;
                                                                            case 2:
                                                                                return <i className="ri-instagram-fill font-size-22" title="Instagram"></i>;
                                                                            case 3:
                                                                                return <i className="ri-whatsapp-fill font-size-22" title="WhatsApp"></i>;
                                                                            case 4:
                                                                                return <FaViber className="font-size-22" title="Viber" />;
                                                                            case 5:
                                                                                return <i className="ri-telegram-fill font-size-22" title="Telegram"></i>;
                                                                            default:
                                                                                return <i className="ri-message-3-fill font-size-22" title="Widget"></i>;
                                                                        }
                                                                    })()
                                                                }
                                                            </span>
                                                        </li>
                                                        <hr className='d-sm-none d-block my-1' />
                                                    </ul>

                                                    {
                                                        // chat && chat.geolocationData && MainService.isJson(chat.geolocationData) ?
                                                        <>
                                                            <ul className="vnd--list-group-horizontal">
                                                                <li>
                                                                    <small className='d-block'>{translationService.translate("TR_COUNTRY")}</small>
                                                                    {
                                                                        item.geolocationData && JSON.parse(item.geolocationData).country_name ?
                                                                            <span>
                                                                                {JSON.parse(item.geolocationData).country_name}
                                                                            </span> :
                                                                            <span>
                                                                                {translationService.translate("TR_NOT_SET")}
                                                                            </span>
                                                                    }
                                                                </li>
                                                                <li>
                                                                    <small className='d-block'>{translationService.translate("TR_FLAG")}</small>
                                                                    <span>
                                                                        {
                                                                            item.geolocationData && JSON.parse(item.geolocationData).country ?
                                                                                <ReactCountryFlag countryCode={JSON.parse(item.geolocationData).country} svg />
                                                                                :
                                                                                <span>
                                                                                    {translationService.translate("TR_NOT_SET")}
                                                                                </span>
                                                                        }
                                                                    </span>
                                                                </li>

                                                                <li>
                                                                    <small className='d-block'>{translationService.translate("TR_CITY")}</small>
                                                                    {
                                                                        item.geolocationData && JSON.parse(item.geolocationData).city ?
                                                                            <span>
                                                                                {JSON.parse(item.geolocationData).city}
                                                                            </span>
                                                                            :
                                                                            <span>
                                                                                {translationService.translate("TR_NOT_SET")}
                                                                            </span>
                                                                    }
                                                                </li>
                                                            </ul>
                                                            <ul className="vnd--list-group-horizontal">
                                                                <li>
                                                                    <small className='d-block'>{translationService.translate("TR_IP")}</small>
                                                                    {
                                                                        item.geolocationData && JSON.parse(item.geolocationData).ip ?
                                                                            <span>
                                                                                {JSON.parse(item.geolocationData).ip}
                                                                            </span>
                                                                            : <span>
                                                                                {translationService.translate("TR_NOT_SET")}
                                                                            </span>
                                                                    }
                                                                </li>
                                                                <li>
                                                                    <small className='d-block'>{translationService.translate("TR_UTC_OFFSET")}</small>
                                                                    {
                                                                        item.geolocationData && JSON.parse(item.geolocationData).utc_offset ?
                                                                            <span>
                                                                                {JSON.parse(item.geolocationData).utc_offset}
                                                                            </span>
                                                                            : <span>
                                                                                {translationService.translate("TR_NOT_SET")}
                                                                            </span>
                                                                    }
                                                                </li>
                                                            </ul>
                                                        </>
                                                        // : null
                                                    }
                                                    {
                                                        <ul className='vnd--list-group-horizontal w-auto'>
                                                            <li style={{ minWidth: "70px" }}>
                                                                <small className='d-block'>{translationService.translate("TR_LOGGED")}</small>
                                                                <span>
                                                                    {
                                                                        item.scriptAttributeAuth ?
                                                                            translationService.translate("TR_YES") :
                                                                            translationService.translate("TR_NO")
                                                                    }
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    }
                                                </div>
                                            </div>
                                        </Link>
                                    </li>
                                ) : <NoData width={200} height={200} />
                            }
                            {/* {
                                users && users.length && pagination ?
                                    pagination.currentPage === pagination.totalPages ? null :
                                        <li onClick={() => getChatWithPagination(currentPage + 1)}>
                                            <Link className="underline text-center text-brand" to="#">{translationService.translate("TR_MORE")}</Link>
                                        </li>
                                    : null
                            } */}
                            {
                                users && users.length ?
                                    <li>
                                        <hr />
                                        <div className="vnd--m-block light-brand-background mb-3">
                                            <div className="vnd--info-block">
                                                <AiOutlineInfoCircle />
                                                <p className='m-0'>{translationService.translate("TR_HISTORY_MESSAGE")}</p>
                                            </div>
                                        </div>
                                    </li>
                                    : null
                            }
                            {
                                users && users.length && pagination && pagination.totalPages > 1 ?
                                    <li onClick={() => getChatWithPagination(currentPage + 1)}>
                                        <Link className="underline text-center text-brand" to="#">{translationService.translate("TR_MORE")}</Link>
                                    </li>
                                    : null
                            }

                        </ul>
                    </SimpleBar>

                </div>
            </div>
        }
        <UserChat
            currentChatPage={currentChatPage}
            chatPagination={chatPagination}
            getCurrentUserChat={getCurrentUserChat}
            chatTopSpinner={chatTopSpinner}
        />
    </Auxiliary> : null
}

export default withRouter(Chats)
